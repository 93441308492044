import { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { utilMethods } from '../util/utilMethods';
//import { firestoreMethods } from '../firebase/firestoreMethods';
//import { firestoreRest } from '../firebase/RestProvider';
import { firebaseAuth } from '../firebase/AuthProvider';



function Admin() {
  
  const navigate = useNavigate();
  const { user, loading } = useContext(firebaseAuth);

  // redirect Use Effect
  useEffect(() => {
    utilMethods.customLog([["MyRest -> UseEffect -> Route", `loading: ${loading}, user: ${user}`]]);
    if (!loading) {
      if (!user) navigate("/");
      //ADMIN CHECK
      //console.log("ADmin", process.env.REACT_APP_API_KEY, "what ", process.env, "love: ", process.env.REACT_APP_ADMIN)

      if (user.uid !== process.env.REACT_APP_ADMIN) navigate("/");
    }
  }, [user, loading, navigate]);

  if (!user || loading) return null;
  return (

    <>
      <div>Admin</div>
     
      
    </>
      
  );

}


export default Admin;


import { createContext, useState, useEffect, useContext } from 'react';
import { firestoreMethods } from './firestoreMethods';
import { firebaseAuth } from './AuthProvider';

import { utilMethods } from '../util/utilMethods';



export const firestoreRest = createContext();

const RestProvider = (props) => {
  const [dbUser, setDbUser] = useState();
  const [myRest, setMyRest] = useState();
  const [refresh, setRefresh] = useState(true);
  //const [restLoading, setRestLoading] = useState(true);

  const { user } = useContext(firebaseAuth);

  // fetch DB user Use Effect
  useEffect(() => {
    utilMethods.customLog([["RestProvider -> dbUser UseEffect -> Enter", `user: ${user}`]]);

    if (!user) {
      setDbUser(null);
      setRefresh(true);
      utilMethods.customLog([["RestProvider -> dbUser UseEffect -> Clearing User", `user: ${user}`]]);
      return;
    }

    let active = true;

    const fetchUser = async () => {
      const newData = await firestoreMethods.getUser(user);
      if (active && refresh){
        //console.log("BANGO");
        setDbUser({...newData});
        setRefresh(false);
        utilMethods.customLog([["RestProvider -> dbUser UseEffect -> Setting User", `user: ${user}`]]);
      }
    };

    fetchUser();
    
    return () => {
      active = false;
    };
  }, [user, refresh]);

  //}, [user, loading, navigate, refresh]);

  // fetch Rest Use Effect
  useEffect(() => {
    utilMethods.customLog([["RestProvider -> myRest UseEffect -> Enter", `dbUser: ${dbUser}`]]);

    if (!dbUser) {
      utilMethods.customLog([["RestProvider -> myRest UseEffect -> Clearing Rest", `dbUser: ${dbUser}`]]);
      setMyRest(null);
      return;
    }

    let active = true;

    const lookForVerified = async (newData) => {
      const newFoundRestaurant= await firestoreMethods.checkVerifiedUser(dbUser);
      if (active && newFoundRestaurant){
        utilMethods.customLog([["RestProvider -> lookForVerified -> found", `dbUser: ${dbUser.id}, newData: ${newData}`]]);
        setMyRest(newFoundRestaurant);
      } else {
        utilMethods.customLog([["RestProvider -> lookForVerified -> not found", `dbUser: ${dbUser.id}, newData: ${newData}`]]);
        setMyRest(newData);
      }
    };

    const fetchRest = async () => {
      const newData = await firestoreMethods.getRest(dbUser);
      if (active){
        await lookForVerified(newData);
      }
    };

    fetchRest();
    
    return () => {
      active = false;
    };
  }, [dbUser]);

  
  return (
    <firestoreRest.Provider
    value={{
      dbUser,
      myRest,
      setRefresh,
    }}>
      {props.children}
    </firestoreRest.Provider>
    
  );
};

export default RestProvider;
//export default withRouter(AuthProvider);



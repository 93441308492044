import db from "./firebaseApp";
import { 
  doc, 
  query, 
  collection, 
  getDocs,
  getDoc,
  setDoc, 
  addDoc,
  updateDoc,
  where,
  limit,
  arrayUnion, 
  arrayRemove,
  documentId,
} from 'firebase/firestore'
import { utilMethods } from '../util/utilMethods';


// firebase Firestore helper methods go here... 

export const firestoreMethods = {
  
  getUser: async (user) => {
    try {
      if (user.uid) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          utilMethods.customLog([["firestoreMethods -> getUser", {...docSnap.data(), id: docSnap.id}]]);
          return({...docSnap.data(), id: docSnap.id});
        } else {
          // doc.data() will be undefined in this case
          utilMethods.customLog([["firestoreMethods -> getUser", "user does not exist"]]);
          // add user if one does not yet exist
          const createData = {
            name: user.displayName,
            authProvider: user.providerData[0].providerId,
            email: user.email,
            rests: [],
          }
          await setDoc(doc(db, "users", user.uid), createData);
          return({...createData, id: user.uid});
        }
      }
      //setRefreshDbUser(false);
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> getUser -> error", err]]);
    }
  },

  getRest: async (dbUser) => {
    try {
      if (dbUser.rests.length > 0) {
        //get user's restaurant
        
          const restRef = doc(db, "rests", dbUser.rests[0]);
          const restSnap = await getDoc(restRef);
          if (restSnap.exists()) {
            utilMethods.customLog([["firestoreMethods -> getRest", {...restSnap.data(), id: restSnap.id}]]);
            return ({...restSnap.data(), id: restSnap.id});
          } else {
            utilMethods.customLog([["firestoreMethods -> getRest", "rest does not exist"]]);
          }
      } else {
        utilMethods.customLog([["firestoreMethods -> getRest", "Empty Rest"]]);
        return ({});
      }
      
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> getRest -> error", err]]);
    }
  },

  getAllVendors: async (myRest) => {
    try {
      utilMethods.customLog([["firestoreMethods -> getAllVendors", ""]]);

      const tempVendorArray = [];
      //const tempRestArray = [];
      let restExists = myRest && Object.keys(myRest).length !== 0;

      // TODO: may be a better way to optimize (e.g. exclude private, get all private vendors separately)
      const q = query(collection(db, "vendors"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().private) {
          if (restExists && myRest.vendors && myRest.vendors.includes(doc.id)) {
            tempVendorArray.push({...doc.data(), id: doc.id});
          }
        } else {
          tempVendorArray.push({...doc.data(), id: doc.id});
        }
      });
      //console.log("tempVendorArray", tempVendorArray);
      return (tempVendorArray);
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> getAllVendors -> error", err]]);
    }
  },

  getRestVendors: async (myRest) => {
    try {
      utilMethods.customLog([["firestoreMethods -> getRestVendors", `vendors: ${myRest.vendors}`]]);

      const tempRestArray = [];
      const vendorsExist = myRest && Object.keys(myRest).length !== 0 
        && myRest.vendors && myRest.vendors.length !== 0;
      const ids = [...myRest.vendors];
      
      // don't run if there aren't any ids 
      if (vendorsExist) {

        while (ids.length) {
          // firestore limits batches to 10
          const batch = ids.splice(0, 10);
          const q = query(
            collection(db, "vendors"),
            where(documentId(), "in", 
              [...batch]
            ),
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            tempRestArray.push({...doc.data(), id: doc.id});
          }); 
        }

        return tempRestArray;

      } else {
        return tempRestArray;
      }
      
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> getRestVendors -> error", err]]);
    }
  },

  getVendor: async (vendorId) => {
    try {
      utilMethods.customLog([["firestoreMethods -> getVendor", ""]]);

      const vendorRef = doc(db, "vendors", vendorId);
      const vendorSnap = await getDoc(vendorRef);
      if (vendorSnap.exists()) {
        utilMethods.customLog([["firestoreMethods -> getVendor", {...vendorSnap.data(), id: vendorSnap.id}]]);
        return ({...vendorSnap.data(), id: vendorSnap.id});
      } else {
        utilMethods.customLog([["firestoreMethods -> vendorSnap", "Vendor does not exist"]]);
      }
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> getVendor -> error", err]]);
    }
  },

  checkVerifiedUser: async (dbUser) => {
    try {
      let foundUserAndRest = null;
      utilMethods.customLog([["firestoreMethods -> checkVerifiedUser", ""]]);
      
      var restData = null;
      var adminType = null;

      var searchAdmin = {
        accepted: false,
        email: dbUser.email,
        id: '',
        name: '',
      }

      //first check managers
      const q = query(collection(db, "rests"), where("managers", "array-contains", searchAdmin));
      
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (foundDoc) => {
        utilMethods.customLog([["firestoreMethods -> checkVerifiedUser", `BINGO MANAGER: ${foundDoc.id}`]]);
        restData = {...foundDoc.data(), id: foundDoc.id}; 
        adminType = "MANAGER";  
      });

      // if not found, next check admins
      if (!restData) {
        const q2 = query(collection(db, "rests"), where("admins", "array-contains", searchAdmin));
        
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach(async (foundDoc2) => {
          utilMethods.customLog([["firestoreMethods -> checkVerifiedUser", `BINGO ADMIN: ${foundDoc2.id}`]]);
          restData = {...foundDoc2.data(), id: foundDoc2.id};     
          adminType = "ADMIN";  
        });
      }

      if (restData) {
        const userRef = doc(db, "users", dbUser.id);
        await updateDoc(userRef, {
          rests: [restData.id],
        });
        
        // update rest admins to be verified
        // remove the manager we are going to update, so we can overwrite it
        let newAdmin = {
          accepted: true,
          email: dbUser.email,
          id: dbUser.id,
          name: dbUser.name,
        };

        if (adminType === "MANAGER") {
          restData.managers = restData.managers.filter(item => item.email !== dbUser.email);
          restData.managers.push(newAdmin);
        } else {
          restData.admins = restData.admins.filter(item => item.email !== dbUser.email);
          restData.admins.push(newAdmin);
        }
        
        //overwrite restaurant
        const restRef = doc(db, "rests", restData.id);
        await setDoc(restRef, restData);
        foundUserAndRest = restData;
      }
      
      return foundUserAndRest;
      
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> checkVerifiedUser -> error", err]]);
    }
  },

  updateUser: async (dbUser, name, value) => {
    try {
      utilMethods.customLog([["firestoreMethods -> updateUser", ]]);

      // update dbUser with the restaurant
      const userRef = doc(db, "users", dbUser.id);
      await updateDoc(userRef, {
        welcome: value,
      });
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> updateUser -> error", err]]);
    }
  },

  setRest: async (dbUser, myRestName) => {

    try {

      const createData = {
        name: myRestName,
        admins: [
          {
            id: dbUser.id, 
            email: dbUser.email,
            name: dbUser.name,
            accepted: true,
          }
        ],
        managers: [],
        vendors: [],
        history: [
          {
            op: "created",
            date: new Date(),
            user_id: dbUser.id, 
            user_email: dbUser.email, 
          }
        ],
      }

      //create restaurant
      const restRef = await addDoc(collection(db, "rests"), createData);
      
      // update dbUser with the restaurant
      const userRef = doc(db, "users", dbUser.id);
      await updateDoc(userRef, {
        rests: [restRef.id],
      });
      //setMyRest({...createData, id: restRef.id});
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> setRest -> error", err]]);
    }

  },

  addAdmin: async (type, userEmail, myRestId, dbUser) => {
    try {
      const historyStamp = {
        op: `added ${userEmail} as a ${type}`,
        date: new Date(),
        user_id: dbUser.id, 
        user_email: dbUser.email, 
      };

      const adminMap = {
        id: '', 
        email: userEmail,
        name: '',
        accepted: false,
      };

      const restRef = doc(db, "rests", myRestId);
      
      if (type === 'Admin') {
        await updateDoc(restRef, 
          {
            admins: arrayUnion(adminMap),
            history: arrayUnion(historyStamp)
          });
      } else {
        await updateDoc(restRef, 
          {
            managers: arrayUnion(adminMap),
            history: arrayUnion(historyStamp)
          });
      }

    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> addAdmin -> error", err]]);
    }
  },

  removeAdmin: async (type, user, myRest, dbUser) => {
    try {
      const historyStamp = {
        op: `removed ${user.email} with role '${type}'`,
        date: new Date(),
        user_id: dbUser.id, 
        user_email: dbUser.email, 
      };

      myRest.history.push(historyStamp);
      //const restRef = doc(db, "rests", myRest.id);

      if (type === 'Admin') {
        myRest.admins = myRest.admins.filter(item => item.email !== user.email)
      } else {
        myRest.managers = myRest.managers.filter(item => item.email !== user.email)
      }
      
      // overwrite myRest
      const restRef = doc(db, "rests", myRest.id);
      await setDoc(restRef, myRest);

    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> removeAdmin -> error", err]]);
    }
  },

  addVendor: async (inputs, ratingInputs, dbUser, myRest) => {
    try {
      const vendorHistoryStamp = {
        op: "created",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      const vendorHistoryStamp2 = {
        op: "recommended",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      inputs.history.push(vendorHistoryStamp);
      inputs.history.push(vendorHistoryStamp2);

      const rating = {
        note: ratingInputs.note || '',
        rating: ratingInputs.rating,
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id,
        rating_id: Math.floor(Math.random() * 100000000).toString(),
        reveal: ratingInputs.reveal || '',
      }

      inputs.ratings.push(rating);

      // add vendor to Vendors
      //console.log("inputs", inputs);
      const vendorRef = await addDoc(collection(db, "vendors"), inputs);
      
      // update restaurant with added vendor
      await firestoreMethods.addVendorToRest(vendorRef.id, inputs, myRest.id, dbUser, true);
      return vendorRef.id;

    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> addVendor -> error", err]]);
    }
  },

  updateVendor: async (vendor, inputs, dbUser, myRest) => {
    try {
      const historyStamp = {
        op: "updated basic details",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      inputs.history.push(historyStamp);

      // overwrite vendor with inputs
      const vendorRef = doc(db, "vendors", vendor.id);
      await setDoc(vendorRef, inputs);
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> updateVendor -> error", err]]);
    }
  },

  updateVendorContact: async (vendor, contactInputs, dbUser, myRest) => {
    try {
      const historyStamp = {
        op: "updated contact",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      //vendor.contacts.push(inputs);
      const filteredContacts = vendor.contacts.filter((contact) => contact.contact_id !== contactInputs.contact_id);
      vendor.contacts = ([contactInputs, ...filteredContacts]);
      vendor.history.push(historyStamp);

      // overwrite vendor with inputs
      const vendorRef = doc(db, "vendors", vendor.id);
      await setDoc(vendorRef, vendor);
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> updateVendorContact -> error", err]]);
    }
  },

  addVendorContact: async (vendor, contactInputs, dbUser, myRest) => {
    try {
      const historyStamp = {
        op: "added contact",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      vendor.contacts.push(contactInputs);
      vendor.history.push(historyStamp);

      // overwrite vendor with inputs
      const vendorRef = doc(db, "vendors", vendor.id);
      await setDoc(vendorRef, vendor);
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> addVendorContact -> error", err]]);
    }
  },

  removeVendorContact: async (vendor, contact, dbUser, myRest) => {
    try {
      const historyStamp = {
        op: "removed contact",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      //vendor.contacts.push(contactInputs);
      //remove contact
      vendor.contacts = vendor.contacts.filter(item => item.contact_id !== contact.contact_id)

      vendor.history.push(historyStamp);

      // overwrite vendor with inputs
      const vendorRef = doc(db, "vendors", vendor.id);
      await setDoc(vendorRef, vendor);
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> removeVendorContact -> error", err]]);
    }
  },


  addVendorToRest: async (vendorId, vendor, myRestId, dbUser, op) => {
    try {
      const opTxt = (op) 
        ? `created vendor (${utilMethods.getVendorName(vendor)})`
        : `added vendor (${utilMethods.getVendorName(vendor)}) to Star List`;
      
      utilMethods.customLog([["firestoreMethods -> addVendorToRest", `vendorId: ${vendorId}, myRestId: ${myRestId}`]]);
      const historyStamp = {
        op: opTxt,
        date: new Date(),
        user_id: dbUser.id, 
        user_email: dbUser.email, 
      };

      // update restaurant with added vendor
      const restRef = doc(db, "rests", myRestId);
      /*await setDoc(restRef,
        {
          "vendors":
          {
            [vendorId]: {
            notes:[],
            contacts: [] }
          }
        },
        { merge: true }
      );/*/
      await updateDoc(restRef, {
        vendors: arrayUnion(vendorId),
        history: arrayUnion(historyStamp)
      });
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> addVendorToRest -> error", err]]);
    }
  },

  removeVendorFromRest: async (vendorId, vendor, myRestId, dbUser) => {
    try {
      utilMethods.customLog([["firestoreMethods -> removeVendorFromRest", `vendorId: ${vendorId}, myRestId: ${myRestId}`]]);
      const historyStamp = {
        op: `removed vendor (${utilMethods.getVendorName(vendor)}) from Starred list`,
        date: new Date(),
        user_id: dbUser.id, 
        user_email: dbUser.email, 
      };

      // update restaurant by removing the vendor
      const restRef = doc(db, "rests", myRestId);
      /*await setDoc(restRef,
        {
          "vendors":
          {
            [vendorId]: {
            notes:[],
            contacts: [] }
          }
        },
        { merge: true }
      );/*/
      
      await updateDoc(restRef, {
          vendors: arrayRemove(vendorId),
          history: arrayUnion(historyStamp)
      });
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> removeVendorFromRest -> error", err]]);
    }
  },

  addVendorRating: async (vendor, inputs, dbUser, myRest) => {
    try {
      const historyStamp = {
        op: "rated",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      const restHistoryStamp = {
        op: `rated ${utilMethods.getVendorName(vendor)} as ${inputs.rating}`,
        date: new Date(),
        user_id: dbUser.id, 
        user_email: dbUser.email, 
      };

      if (!vendor.ratings) {vendor.ratings = [];}

      const rating = {
        note: inputs.note,
        rating: inputs.rating,
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id,
        rating_id: Math.floor(Math.random() * 100000000).toString(),
      }
        
      /* 
      // if we rate each service 
      inputs.services.forEach((service) => {
        let tmpRating = {
          service: service,
          note: inputs.note,
          rating: inputs.rating,
          date: new Date(),
          rest: myRest.name,
          rest_id: myRest.id,
          user_id: dbUser.id,
          rating_id: Math.floor(Math.random() * 100000000).toString(),
        }
        vendor.ratings.push(tmpRating);

        // if the service is new for the vendor, also add it to services
        if (!vendor.services.includes(service)) {
          vendor.services.push(service);
        }
      });
      */
      
      // add vendor rating
      vendor.ratings.push(rating)
      vendor.history.push(historyStamp);

      // overwrite vendor with inputs
      const vendorRef = doc(db, "vendors", vendor.id);
      await setDoc(vendorRef, vendor);

      // update restaurant history
      const restRef = doc(db, "rests", myRest.id);
      await updateDoc(restRef, {
          history: arrayUnion(restHistoryStamp)
      });

    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> addVendorRating -> error", err]]);
    }
  },

  updateVendorRating: async (vendor, inputs, dbUser, myRest) => {
    try {
      const historyStamp = {
        op: "updated rating",
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      const restHistoryStamp = {
        op: `updated rating for ${utilMethods.getVendorName(vendor)} as ${inputs.rating}`,
        date: new Date(),
        user_id: dbUser.id, 
        user_email: dbUser.email, 
      };

      //console.log("INPUT", inputs);

      let filteredRatings = vendor.ratings.filter((rating) => rating.rating_id !== inputs.rating_id);
      
      /* no longer rating by service, add if i go back to that again
      // we are going to generate a new ID instead of re-using original one
      // this is because a user is currently allowed to add extra services during rating - just like they can on initial rating
      inputs.services.forEach((service) => {
        let tmpRating = {
          service: service,
          note: inputs.note,
          rating: inputs.rating,
          date: new Date(),
          rest: myRest.name,
          rest_id: myRest.id,
          user_id: dbUser.id,
          rating_id: Math.floor(Math.random() * 100000000).toString(),
        }
        filteredRatings.push(tmpRating);

        // if the service is new for the vendor, also add it to services
        if (!vendor.services.includes(service)) {
          vendor.services.push(service);
        }
      });
      /*/

      filteredRatings.push(inputs)

      vendor.ratings = filteredRatings;
      vendor.history.push(historyStamp);

      // overwrite vendor with inputs
      const vendorRef = doc(db, "vendors", vendor.id);
      await setDoc(vendorRef, vendor);

      // update restaurant history
      const restRef = doc(db, "rests", myRest.id);
      await updateDoc(restRef, {
          history: arrayUnion(restHistoryStamp)
      });

    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> updateVendorRating -> error", err]]);
    }
  },

  voteVendor: async (vendor, dbUser, myRest, vote, note) => {
    try {
      const historyStamp = {
        op: `${(vote > 0) ? "recommended" : "did not recommend"}`,
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id, 
      };

      const restHistoryStamp = {
        op: `${(vote > 0) ? "recommended" : "did not recommend"} ${utilMethods.getVendorName(vendor)}`,
        date: new Date(),
        user_id: dbUser.id, 
        user_email: dbUser.email, 
      };

      if (!vendor.ratings) {vendor.ratings = [];}

      const rating = {
        note: note,
        rating: vote,
        date: new Date(),
        rest: myRest.name,
        rest_id: myRest.id,
        user_id: dbUser.id,
        rating_id: Math.floor(Math.random() * 100000000).toString(),
      }

      //console.log("RAT", rating);

      vendor.ratings.push(rating);
      vendor.history.push(historyStamp);

      // overwrite vendor with inputs
      const vendorRef = doc(db, "vendors", vendor.id);
      await setDoc(vendorRef, vendor);

      // update restaurant history
      const restRef = doc(db, "rests", myRest.id);
      await updateDoc(restRef, {
          history: arrayUnion(restHistoryStamp)
      });

    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> updateVendorRating -> error", err]]);
    }
  },

  getServices: async() => {
    try {
      let services = []
      utilMethods.customLog([["firestoreMethods -> getServices", ""]]);
      const q = query(collection(db, "utils"), where("isService", "==", true), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        services = [...doc.data().services];
      });
      return services;
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> getServices -> error", err]]);
    }
  },

  addService: async (service) => {
    try {
      
      let serviceId = '';
      const q = query(collection(db, "utils"), where("isService", "==", true), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        serviceId = doc.id;
      });
      
      const serviceRef = doc(db, "utils", serviceId);
      await updateDoc(serviceRef, {
          services: arrayUnion(service)
      });
      
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> addService -> error", err]]);
    }
  },

  

  
/*

  arrayUnion,
  arrayRemove,


  updateFavRests: async (myRest, newRestId, setRefreshDbUser) => {
    try {
      const restRef = doc(db, "rests", myRest.id);
      if (myRest.favRests.includes(newRestId)) {
        await updateDoc(restRef, {
          favRests: arrayRemove(newRestId)
        });
      } else {
        await updateDoc(restRef, {
          favRests: arrayUnion(newRestId)
        });
      }
      utilMethods.customLog([["firestoreMethods -> updateUser -> Doc Written with id:", restRef.id]]);
      //await firestoreMethods.getUser(dbUser.id, setDbUser);
      setRefreshDbUser(true);
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> updateUser -> error", err]]);
    }
  },
*/
  
/*
  getRests: async (setRests, setFilteredRests) => {
    try {
      const tempArray = [];
      const q = query(collection(db, "rests"), orderBy("name", "asc"), );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        tempArray.push({...doc.data(), id: doc.id});
      });
      utilMethods.customLog([["firestoreMethods -> getRestaurants -> Rests", tempArray]]);

      setRests(tempArray);
      setFilteredRests(tempArray);
      
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> getRestaurants -> error", err]]);
    }
  },

  getRestAdmins: async (admins, managers, dbUser) => {
    try {
      utilMethods.customLog([["firestoreMethods -> getRestAdmins", `admins: ${admins}, managers: ${managers}, dbUser: ${dbUser} `]]);
      let users = admins.concat(managers);
      let returnUsers = {};
      const colRef = collection(db, "users");
      const q = query(colRef, where(documentId(), 'in', users));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });

      //return setAdminUsers({"admins": [dbuser], "managers": []});

      
    } catch (err) {
      utilMethods.customLog([["firestoreMethods -> removeVendorFromRest -> error", err]]);
    }
  },

  */

}
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { firestoreMethods } from '../firebase/firestoreMethods';
import { utilMethods } from '../util/utilMethods';




export default function AddServiceDialog({open, handleClose, setRefreshServices, addServiceToSelect}) {
  
  const [service, setService] = useState('');

  const handleAdd = async() => {
    if (service !== '') {
      utilMethods.customLog([["AddService -> handleAdd", service]]);
      await firestoreMethods.addService(service);
      setRefreshServices(true);
      addServiceToSelect(service);
    }
    handleClose();
  }

  const handleFormType = (e) => {
    const {value} = e.target;
    setService(value);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add a title for service you wish to add
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Service"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleFormType}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import { createContext } from 'react';
import { getAuth } from 'firebase/auth';
import { useAuthState } from "react-firebase-hooks/auth";

import { authMethods } from './authMethods';
//import { utilMethods } from '../util/utilMethods';



export const firebaseAuth = createContext();

const AuthProvider = (props) => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);

  const join = (inputs, setErrors) => {
    authMethods.join(inputs, setErrors);
  }

  const login = (inputs, setErrors) => {
    authMethods.login(inputs, setErrors);
  }

  const signInWithGoogle = (setErrors) => {
    authMethods.signInWithGoogle(setErrors);
  }

  const signOut = () => {
    authMethods.signOut();
  }

  return (
    <firebaseAuth.Provider
    value={{
      user,
      loading,
      join,
      login,
      signInWithGoogle,
      signOut,
    }}>
      {props.children}
    </firebaseAuth.Provider>
    
  );
};

export default AuthProvider;
//export default withRouter(AuthProvider);



//import {useState } from 'react';
//import { utilMethods } from './util/utilMethods';
import spoons from './assets/green_spoons.png';
import CloseIcon from '@mui/icons-material/Close';
import StarEmpty from '@mui/icons-material/StarOutline';



function Help({handleCancel}) {
  
  return (
    <>
      <div className='view-v-card'>
        <CloseIcon className="new-rest-cancel" onClick={handleCancel}/>
              
        <div className='new-rest-header'>
          Start by searching for a vendor
        </div>
        <div className='new-rest-example'>
          For example, search for <span className='new-rest-italic'>Plumber</span> above
        </div>
        <div className='new-rest-header'>
          Star Vendors for easy access
        </div>
        <div className='new-rest-example'>
          Click on&nbsp; <StarEmpty className='v-card-star-icon'/>
           in the Vendor Card
        </div>
        <div className='new-rest-header'>
          Add your vendors
        </div>
        <div className='new-rest-example'>
          Click on
          <span className='new-rest-orange'>[Add Vendor]</span> above
        </div>
        <div className='new-rest-text'>
          Vendors you mark as Private will only be visible to you
        </div>
        
        <img src={spoons} className='spoons' alt="Spoons"/>
        <div className='new-rest-again-text'>
          To see this again, click<span className='new-rest-orange'>Help</span>above.
        </div>
      </div>
    </>
  );

}

export default Help;


      

                






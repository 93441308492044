// util helper methods go here... 

export const utilMethods = {
  
  customLog: (messages) => {
    if (process.env.REACT_APP_DEBUG === 'dev') {
      messages.forEach(([text, value]) => {
        console.log(text, value);
      });
    }
  },

  formatDate: (dateStr) => {
    if (dateStr) {
      let d = new Date();
      if (dateStr instanceof Date) {
        d = new Date(dateStr);
      } else {
        d = new Date(dateStr.toDate());
      }
      //date.toDate()).toDateString()
      return (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
    } 
  },

  timeSince: (dateStr) => {
    let d = new Date();
    if (dateStr) {
      if (dateStr instanceof Date) {
        d = new Date(dateStr);
      } else {
        d = new Date(dateStr.toDate());
      }
    }

    var seconds = Math.floor((new Date() - d) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'year';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'month';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'day';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }

    if (interval > 1 || interval === 0) {
      intervalType += 's';
    }

    return interval + ' ' + intervalType + ' ago';
  },

  isValidEmail: (email) => {
    return /\S+@\S+\.\S+/.test(email);
  },

  addDefault: (val) => {
    if (val==='' || val===null || val===' ' || val === undefined)
      return "n/a";
    else
      return val;
  },

  getVendorName: (vendor) => {
      return vendor.vendor_name || vendor.contacts[0].contact_name || vendor.services[0];
  },

  getVendorContactPhone: (vendor) => {
      let tmpPhone = '';
      if (vendor.contacts[0].contact_work) return vendor.contacts[0].contact_work + ' (work)';
      if (vendor.contacts[0].contact_cell) return vendor.contacts[0].contact_cell + ' (cell)';
      if (vendor.contacts[0].contact_email) return vendor.contacts[0].contact_email;
      return tmpPhone;
  },

  sortByCustomName: (vendors) => {
    //return vendors;
     return vendors.sort(function(a, b) {
        var x = utilMethods.getVendorName(a);
        var y = utilMethods.getVendorName(b);
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
  },

  sortByListAndName: (myRest, vendors) => {
    //return vendors;
    console.log("ALL", myRest, vendors);
     return vendors.sort(function(a, b) {
        var xx = (myRest.vendors.includes(a.id)) ? 1 : 0;
        var yy = (myRest.vendors.includes(b.id)) ? 1 : 0;
        var x = utilMethods.getVendorName(a);
        var y = utilMethods.getVendorName(b);
        return ((xx < yy) ? 1 : ((xx > yy) ? -1 : 
          ((x < y) ? -1 : ((x > y) ? 1 : 0))
        ));

        //return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
  },

  /* RATINGS **************************************/
  sortRatings: (ratings) => {
     return ratings.sort(function(a, b) {
        var x = a.date;
        var y = b.date;
        return ((x < y) ? 1 : ((x > y) ? -1 : 0));
     });
  },

  getRatingDefaults: () => {
      return [
        {rating:"great", expl:"Strongly recommend"}, 
        {rating:"ok", expl:"Have and would use again"},
        {rating:"poor", expl:"Not a great experience"}, 
      ];
  },

  getRatings: (vendor) => {

    let tmpRatings = {};
    if (vendor.ratings) {
      vendor.ratings.forEach((rating) => {
        if (!(rating.service in tmpRatings)) {
          tmpRatings[rating.service] = [];
        }
        tmpRatings[rating.service].push(
          {
            date: utilMethods.timeSince(rating.date),
            note: rating.note,
            rating: rating.rating,
            rating_id: rating.rating_id,
            rest: rating.rest,
            rest_id: rating.rest_id,
          }
        );
      })
    } 

    return tmpRatings;
    //return {"Refrigiration" : [{restName: "blah blah blah blah blah", id:11, score:'none'}, {restName: "blah2", id: 13, score:'great'}]}

  },

  getRatingToEdit: (ratings, ratingId) => {
    const ratingToEdit = ratings.find((rating) => rating.rating_id === ratingId);
    // the services dropdown expects an array
    
    // NO LONGER using services ratingToEdit["services"] = [ratingToEdit.service];
    return ratingToEdit;
  },

  countRatings: (vendor) => {
    let tmpRating = 0;

    if (vendor.ratings) {
      vendor.ratings.forEach((rating) => {
        (rating.rating > 0) ? tmpRating++ : tmpRating--;
      })
    }
    // NO LONGER using services ratingToEdit["services"] = [ratingToEdit.service];
    return tmpRating;
  },

  /* ... **************************************/


  handleFormType: (e, setInputs) => {
    const {name, value} = e.target;
    setInputs(prev => ({...prev, [name]: value}))
  },

  filterVendors: (inputs, allVendors, myRest, setOnListVendors, setCommunityVendors) => {
      
      // TODO: you can optimize searching within vendors as user is typing up, and resetting back to all vendors if user is deleting letters
      try {
        let filteredVendors = [];
        for (const v of allVendors) {
          let vendorAdded=false;
          for (const k in v) {
            if (vendorAdded) {
              //console.log("vendorAdded", vendorAdded);
              break;
            } 
            // since contacts is now an array of contacts, have to do an additional loop within
            if (!vendorAdded && k === 'contacts') {
                for (const contact in v[k]) {
                  for (const detail in v[k][contact]) {
                    if (detail !== 'contact_id' && (typeof v[k][contact][detail] === 'string' || v[k][contact][detail] instanceof String)) {
                        if (v[k][contact][detail].toLowerCase().includes(inputs.search.toLowerCase())) {
                          //console.log("contacts match", v.id);
                          if (!vendorAdded) {
                            filteredVendors.push(v);
                            vendorAdded = true;
                            break;
                          }
                        }
                    }
                  }
                }
            }

            // since services is now array, have to do an additional loop within
            if (!vendorAdded && k === 'services') {
              for (const service in v[k]) {
                if (v[k][service].toLowerCase().includes(inputs.search.toLowerCase())) {
                  if (!vendorAdded) {
                    filteredVendors.push(v);
                    vendorAdded = true;
                    break;
                  }
                }
              }
            }

            // since ratings is now an array of ratings, have to do an additional loop within
            if (!vendorAdded && k === 'ratings') {
                for (const rating in v[k]) {
                  for (const detail in v[k][rating]) {
                    if (detail === 'note') {
                      if (v[k][rating][detail].toLowerCase().includes(inputs.search.toLowerCase())) {
                        //console.log("contacts match", v.id);
                        if (!vendorAdded) {
                          // adding this IF ABOVE solved a long bug, because the for loop wasn't breaking
                          filteredVendors.push(v);
                          vendorAdded = true;
                          break;
                        }
                      }
                    }
                  }
                }
            }

            
            // private filter
            if (!vendorAdded && k === 'private' && v[k] === true) {
                if ('private'.includes(inputs.search.toLowerCase())) {
                  //console.log("private match", v.id, k, v[k]);
                  filteredVendors.push(v);
                  vendorAdded = true;
                  break;
                }
            }

            // all other vendor fields
            if ((!vendorAdded && k !== 'id') && (typeof v[k] === 'string' || v[k] instanceof String)) {
                if (v[k].toLowerCase().includes(inputs.search.toLowerCase())) {
                  //console.log("other fields match", v.id, k, v[k]);
                  filteredVendors.push(v);
                  vendorAdded = true;
                  break;
                }
            }
          }
          
        }
        
        //console.log("filterVendors", filteredVendors);
        
        // separate filtered vendors into those on list and not on list
        const tmpOnListVendors = filteredVendors ? filteredVendors.filter((vendor) => myRest.vendors.includes(vendor.id)) : [];
        const tmpCommunityVendors = filteredVendors ? filteredVendors.filter((vendor) => !myRest.vendors.includes(vendor.id)) : [];

        setOnListVendors((myRest && myRest.vendors.length > 0) ? utilMethods.sortByCustomName(tmpOnListVendors) : null);
        setCommunityVendors(utilMethods.sortByCustomName(tmpCommunityVendors));
        
      } catch (err) {
        utilMethods.customLog([["Vendors -> filterVendors -> Error", err]]);
      }
    },

}
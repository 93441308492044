import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { utilMethods } from './util/utilMethods';
import { firebaseAnalytics } from './firebase/AnalyticsProvider';


import MoreIcon from '@mui/icons-material/ExpandMore';
import LessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';

function VendorCardDetails({vendor, defaultCollapse}) {
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(defaultCollapse);
  const { trackEvent } = useContext(firebaseAnalytics);

  const handleCollapse = (val) => {
    setCollapse(val);
    trackEvent(val ? "details_c_closed" : "details_c_opened");
  }

  const handleDetailsEdit = () => {
    trackEvent("edit_vendor_details");
    navigate(`/vendors/edit-vendor/${vendor.id}/details`);
  }
  
  return (collapse 
      ? <>
          <div className='v-card-section'>
            <div className='v-card-collapsed-section-header'>
              <div className='v-card-header-row'>
                <div className='v-card-header-label'>Services</div>
                <div className='v-card-header-service'>
                  {vendor.services.join(', ')}  
                </div>
              </div>
              {collapse &&
                <MoreIcon className="collapse" onClick={()=>handleCollapse(false)}/>
              }

            </div>
          </div>
        </>
      : <>
          <div className='v-card-section'>
            <div className='v-card-section-header'>
              <div className='view-v-card-title'>Basic Details</div>
              <div className='v-card-action' onClick={handleDetailsEdit}>
                <EditIcon className='v-card-icon'/>Edit
              </div>
              
              {!collapse &&
                <LessIcon className="collapse" onClick={()=>handleCollapse(true)}/>
              }
            </div>

            <div className='v-card-row'>
              <div className='view-v-details-row-label'>Name</div>
              <div className='view-v-details-row-value'>
                {utilMethods.addDefault(vendor.vendor_name)}
              </div>
            </div> 

            <div className='v-card-row'>
              <div className='view-v-details-row-label'>Website</div>
              <div className='view-v-details-row-value'>
                {utilMethods.addDefault(vendor.vendor_site)}
              </div>
            </div> 

            <div className='v-card-row'>
                <div className='view-v-details-row-label'>Services</div>
                <div className='v-card-row-service'>
                  {vendor.services.join(', ')}  
                </div>
                {/*vendor.services.map((service, index) => {
                  return (
                    <div key={index} className='v-card-row-service'>
                      {service}  
                    </div>
                    );
                })
                */}
                
           
            </div> 
          </div>

        </>
      

  );

}


export default VendorCardDetails;



import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { firebaseAuth } from '../firebase/AuthProvider';
import { firestoreMethods } from '../firebase/firestoreMethods';
import { firestoreRest } from '../firebase/RestProvider';
import { utilMethods } from '../util/utilMethods';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Header from '../Header';
import VendorDetailsForm from './VendorDetailsForm';
import VendorContactsForm from './VendorContactsForm';
import RateForm from './RateForm';


function AddVendor() {
  const { user } = useContext(firebaseAuth);
  const { myRest, dbUser, setRefresh } = useContext(firestoreRest);
  const navigate = useNavigate();

  const [inputs, setInputs] = useState();

  
  const handleAdd = async(inputs) => {
    utilMethods.customLog([["AddVendor -> handleAdd", ""]]);
    //const vendorId = await firestoreMethods.addVendor(inputs, dbUser, myRest);
    //setRefresh(true);
    //navigate(-1);
    //navigate("/vendors/"+type+"/"+vendorId);
    setInputs(inputs);
  }

  const handleAddAfterContacts = async(contactInputs) => {
    utilMethods.customLog([["AddVendor -> handleAddAfterContacts", ""]]);
    //await firestoreMethods.addVendor(inputs, contactInputs, dbUser, myRest);
    //setRefresh(true);
    //navigate('/vendors');
    setInputs(prev => (
      {
        ...prev, 
        contacts: [contactInputs],
      }));
  }

  const handleAddAfterRatings = async(ratingInputs) => {
    utilMethods.customLog([["AddVendor -> handleAddAfterRatings", inputs]]);
    const vendorId = await firestoreMethods.addVendor(inputs, ratingInputs, dbUser, myRest);
    setRefresh(true);
    navigate(`/vendors/${vendorId}`);
  }
  
  

  if (!user) navigate("/");
  if (!myRest) return null;

  return (
    <>
      <Header/>
      <div className='back-header'>
        <ArrowBackIcon className="back-icon" onClick={()=>navigate('/vendors')}/>
        <h2>Import New Vendor</h2>      
      </div>

      {(!inputs) && 
        <VendorDetailsForm
          myRest={myRest} 
          handleAdd={handleAdd}
          handleCancel={() => navigate('/vendors')}
        /> 
      }
      
      {(inputs && inputs.contacts.length === 0) &&
        <VendorContactsForm
          handleAdd={handleAddAfterContacts}
          handleCancel={() => navigate('/vendors')}
          label="Continue"
        />
      }

      {(inputs && inputs.ratings.length === 0 && inputs.contacts.length > 0) &&
        <RateForm
          handleAdd={handleAddAfterRatings}
          type="NEW_VENDOR"
          handleCancel={() => navigate('/vendors')}
        />
      }
    </>
  );

}


export default AddVendor;
/*

{(!inputs) 
        ? 
         <p>Instead of filling out this form, you can always just forward emails with vendor details to vendors@spoonvillage.com.</p>
        : 
         <p>Add vendor contact.</p>
      }
      <hr/>

      */

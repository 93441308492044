//import auth from "./firebaseApp";
import { 
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';

//import { firestoreMethods } from './firestoreMethods';
import { utilMethods } from '../util/utilMethods';


// firebase Auth helper methods go here... 
export const authMethods = {

  join: async (inputs, setErrors) => {
    try {
      const auth = getAuth();
      const res = await createUserWithEmailAndPassword(auth, inputs.email, inputs.password);
      const user = res.user;
      utilMethods.customLog([["Join User", user]]);
      setErrors(null);
    } catch (err) {
      utilMethods.customLog([["Join error: ", err]]);
      switch(err.code) {
        case "auth/email-already-in-use":
          setErrors(["This email is already in use.  Please pick a different one."]);
          break;
        default:
          setErrors([err.message])
      }
    }   
  },

  login: async (inputs, setErrors) => {
    try {
      const auth = getAuth();
      const res = await signInWithEmailAndPassword(auth, inputs.email, inputs.password);
      const user = res.user;
      utilMethods.customLog([["Login User", user]]);
      setErrors(null);
    } catch (err) {
      utilMethods.customLog([["Login error: ", err]]);
      switch(err.code) {
        case "auth/invalid-email":
          // should not happen because i check for email
          setErrors(["The User ID or Password is incorrect. Please try again."]);
          break;
        case "auth/user-not-found":
          setErrors(["The User ID or Password is incorrect. Please try again."]);
          break;
        case "auth/wrong-password":
          setErrors(["The User ID or Password is incorrect. Please try again."]);
          break;
        case "auth/network-request-failed":
          setErrors(["Please check your network connection, and try again."]);
          // TODO: later implement offline dev mode
          //setAuthUser({});
          break;
        case "auth/too-many-requests":
          setErrors(["You have attempted to login too many times. Please try again later."]);
          break;
        default:
          setErrors([err.message]);
      }
    }   
  },

  signInWithGoogle: async (setErrors) => {
    try {
      const auth = getAuth();
      const googleProvider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      utilMethods.customLog([["Google user", user]]);
      setErrors(null);
    } catch (err) {
      utilMethods.customLog([["Google error: ", err]]);
      setErrors(["Sign-in with google failed."]);
    }   
  },

  signOut: async () => {
    const auth = getAuth();
    signOut(auth);
  }, 
}

import * as React from 'react';
import { useState, useEffect } from 'react';
import { firestoreMethods } from '../firebase/firestoreMethods';
import { utilMethods } from '../util/utilMethods';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import AddServiceDialog from './AddServiceDialog';

export default function ServicesSelect({inputs, setInputs, vendorServices}) {
  const [open, setOpen] = useState(false);
  const [refreshServices, setRefreshServices] = useState(vendorServices ? false : true);
  const [selectServices, setSelectServices] = useState(vendorServices ? vendorServices : null);


  const handleChange = (e, newVal) => {
    
    setInputs(prev => (
      {
        ...prev, 
        services: newVal,
      }));
  };

  const addServiceToSelect = (value) => {
    if (vendorServices) {
      setSelectServices([...vendorServices, value]);
    }
    setInputs(prev => (
      {
        ...prev, 
        services: [...inputs.services, value],
      }));
  }

  // fetch services Use Effect
  useEffect(() => {
    utilMethods.customLog([["Services -> UseEffect -> Fetch Services"]]);

    let active = true;

    const fetchServices = async () => {
      utilMethods.customLog([["Services -> fetchServices", ""]]);
      const newData = await firestoreMethods.getServices();
      if (active){
        setSelectServices(newData.sort());
        setRefreshServices(false);
      }
    };  

    // update dropdown values. only fetch all services if a new vendor
    if (refreshServices) {
      if (!vendorServices) {
        fetchServices();
      }
    }
    
    return () => {
      active = false;
    };
  }, [refreshServices, vendorServices]);

  if (!selectServices) return null;

  return (
    <>
      <AddServiceDialog 
        open={open} 
        handleClose={()=> setOpen(false)}
        setRefreshServices={setRefreshServices}
        addServiceToSelect={addServiceToSelect}
      />
      <div className='form-label-group'>
        <div className='form-label'>Vendor Services</div>
      </div>
      <div className='form-input'>
        <Autocomplete
          multiple
          options={selectServices}
          noOptionsText=
            {
              <div>This service does not exist.  
                  <div className='service-add' onClick={() => setOpen(true)}>Add Service</div>
              </div>
            }
          getOptionLabel={(option) => option}
          defaultValue={inputs.services}
          renderInput={(params) => (
            <TextField 
             {...params} 
             InputProps={{...params.InputProps, disableUnderline: true}}
              variant="standard"
            />
          )}
          name="services"
          value={inputs.services}
          onChange={handleChange}

        />
      </div>
    </>

  );
}
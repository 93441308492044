import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { firebaseAuth } from './firebase/AuthProvider';
import { utilMethods } from './util/utilMethods';
import { firestoreMethods } from './firebase/firestoreMethods';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Header from './Header';
import VendorCard from './VendorCard';



function VendorView() {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  
  const { user, loading } = useContext(firebaseAuth);
  const [vendor, setVendor] = useState();
  
  // fetch Vendor Use Effect
  useEffect(() => {
    utilMethods.customLog([["VendorView -> UseEffect -> Fetch Vendor", `vendorId: ${vendorId}`]]);

    if (!vendorId) return;

    let active = true;

    const fetchVendor = async () => {
      utilMethods.customLog([["VendorView -> fetchVendor", ""]]);
      const newData = await firestoreMethods.getVendor(vendorId);
      if (active){
        setVendor(newData);
      }
    };  

    //if (refreshVendor) {
      fetchVendor();
    //}
    
    return () => {
      active = false;
    };
  }, [vendorId]);

  // redirect Use Effect
  useEffect(() => {
    utilMethods.customLog([["VendorView -> UseEffect -> Route", `loading: ${loading}, user: ${user}`]]);
    if (!loading) {
      if (!user) navigate("/");
    }
  }, [user, loading, navigate]);

  if (!vendor) return null;


  return (
    <>
      <Header/>
      <div className='back-header'>
        <ArrowBackIcon className="back-icon" onClick={()=>navigate('/vendors')}/>
        <h2>Back to Search</h2>      
      </div>
      <VendorCard key={vendor.id}
        vendor={vendor} 
        onList={true}
        defaultCollapse={false}
      />
    </>
  );

}


export default VendorView;



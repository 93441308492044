import VendorCard from './VendorCard';

function VendorsShow({type, vendors}) {

  const sectionStyle = (type === 'ON_LIST') ? 'v-card-group-title v-card-group-title-on-list' : 'v-card-group-title';
  const sectionTitle = (type === 'ON_LIST') ? "Starred Vendors" : "Vendors from the Community";

  
  return ((vendors) 
      ? 
        <>
          <div className={sectionStyle}>{sectionTitle}</div>
          {(vendors.length === 0) 
          ? 
            <div className='v-no-vendors'>
              No vendors match your search
            </div>
          :
            <>
              {vendors && vendors.map((vendor, index) => {
                  return (
                    
                      <VendorCard key={vendor.id}
                          vendor={vendor} 
                          onList={(type === 'ON_LIST') ? true : false}
                          defaultCollapse={true}
                      />
                    
                  );
              })}
            </>
          }
        </> 
      :
        <>
          {(type === 'ON_LIST') && 
            <div className='view-v-card v-card-on-list'>
              <div className='new-rest-header'>
                Your vendor list is empty.
              </div>
              
              <div className='new-rest-again-text'>
                Click<span className='new-rest-orange'>Help</span>above for more details.
              </div>
            </div>
          }
        </>
  );

}


export default VendorsShow;
        
import { useState, useContext } from 'react';
import { utilMethods } from './util/utilMethods';
import { firebaseAnalytics } from './firebase/AnalyticsProvider';


import LessIcon from '@mui/icons-material/ExpandLess';
import HistoryIcon from '@mui/icons-material/History';
import Tooltip from '@mui/material/Tooltip';


function VendorCardDetails({vendor, defaultCollapse}) {
  const [collapse, setCollapse] = useState(defaultCollapse);
  const { trackEvent } = useContext(firebaseAnalytics);

  const handleCollapse = (val) => {
    setCollapse(val);
    trackEvent(val ? "history_c_closed" : "history_c_opened");
  }

  const lastHistory = (vendor && vendor.history) ? vendor.history[vendor.history.length - 1] : {};

    
  return (collapse 
      ? <>
          <div className='v-card-section'>
            <div className='v-card-collapsed-section-header'>
              <div className='v-card-history'>
                {utilMethods.timeSince(lastHistory.date)}: {lastHistory.op} by {lastHistory.rest} 
              </div>
              {collapse &&
                <Tooltip title='Show activity on this vendor'>
                  <HistoryIcon className='collapse v-card-history' onClick={()=>handleCollapse(false)}/>
                </Tooltip>
              }

            </div>
          </div>
        </>
      : <>
          <div className='v-card-section'>
            
            <div className='v-card-section-header'>
              <div className='view-v-card-title'>Activity</div>
              {!collapse &&
                <LessIcon className="collapse" onClick={()=>handleCollapse(true)}/>
              }
            </div>

            {vendor.history.map((history, index) => {
              return(<div key={index+history.rest_id+history.user_id} className='v-card-row'>
                <div className='view-v-details-row-label'>{utilMethods.timeSince(history.date)}</div>
                <div className='v-card-history'>
                  <span className='v-card-history-op'>{history.op}</span> by {history.rest}
                </div>
              </div>)
            })}
            
          </div>

        </>
      

  );

}


export default VendorCardDetails;



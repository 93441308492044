import { useState, useContext } from 'react';
import { utilMethods } from '../util/utilMethods';
import { firestoreMethods } from '../firebase/firestoreMethods';
import { firestoreRest } from '../firebase/RestProvider';
import { firebaseAnalytics } from '../firebase/AnalyticsProvider';

import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';

import AddVoteNoteDialog from './AddVoteNoteDialog';


function Vote({vendor}) {

  const { myRest, dbUser, setRefresh } = useContext(firestoreRest);
  const { trackEvent } = useContext(firebaseAnalytics);

  const [vote, setVote] = useState(false);
  const [votedSnack, setVotedSnack] = useState(false);
  const [openVoteNoteDialog, setOpenVoteNoteDialog] = useState(false);

  const voteTally=vendor && utilMethods.countRatings(vendor);
  const voteStyle = (voteTally > 0) ? 'vote-counter vote-great' : 'vote-counter vote-poor';
  const snackText = (vote > 0) 
    ? `Recommended ${vendor && utilMethods.getVendorName(vendor)}`
    : `Did not recommend ${vendor && utilMethods.getVendorName(vendor)}`;


  const voteClicked = (vote) => {
    utilMethods.customLog([["Vote -> voteClicked", vote]]);
    //await firestoreMethods.voteVendor(vendor.id, vendor, myRest.id, dbUser, "DOWN");
    //setRefresh(true);
    setVote(vote);
    setOpenVoteNoteDialog(true);
  }

  const handleUpdateNote = async(note) => {
    utilMethods.customLog([["Vote -> handleUpdateNote", note]]);
    await firestoreMethods.voteVendor(vendor, dbUser, myRest, vote, note);
    trackEvent((vote > 0) ? "vote_rec" : "vote_not_rec");
    setRefresh(true);
    setVotedSnack(true);
  }

  const handleVoteSnackClose = () => {
    setVotedSnack(false);
  }

  const VoteSnackAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleVoteSnackClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <div className='vote'>
        <Tooltip title='Recommend this vendor'>
          <ThumbUp 
            className='vote-arrow vote-arrow-up' 
            onClick={() => voteClicked(1)}
          />
        </Tooltip>
        <div className={voteStyle}>{voteTally}</div>
        <Tooltip title='Do not recommend this vendor'>
          <ThumbDown 
            className='vote-arrow vote-arrow-down'
            onClick={() => voteClicked(-1)}
          />
        </Tooltip>
      </div>

      <AddVoteNoteDialog 
        open={openVoteNoteDialog} 
        handleClose={()=> setOpenVoteNoteDialog(false)}
        vote={vote}
        updateNote={handleUpdateNote}
      />
      <Snackbar
        open={votedSnack}
        autoHideDuration={8000}
        onClose={handleVoteSnackClose}
        message={snackText}
        action={VoteSnackAction}
      />              
    </>
  );

}

export default Vote;


      

                






import { useState } from 'react';
import { useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";

import { firebaseAnalytics } from './firebase/AnalyticsProvider';
import { firebaseAuth } from './firebase/AuthProvider';
import { firestoreRest } from './firebase/RestProvider';

import Menu from './Menu';

function Header({showHelp}) {

  const [menuOpen, setMenuOpen] = useState(false);
  const { signOut } = useContext(firebaseAuth);
  const { myRest } = useContext(firestoreRest);
  const { trackEvent } = useContext(firebaseAnalytics);
  const restExists = myRest && Object.keys(myRest).length !== 0;


  const navigate = useNavigate();

  const menuSelected = (item) => {
    switch (item) {
      case "RESTAURANT":
        navigate("/my-venue");
        break;
      case "LOGOUT":
        signOut();
        break;
      default:
        break;
    }
  }

  const handleMenuClick = () => {
    setMenuOpen(true);
    trackEvent("hamburger_click");
  }

  const handleAddVendorClick = () => {
    trackEvent("add_vendor");
  }

  return (
    <>
      <div className="header">
        <div className='menu-container' onClick={handleMenuClick}>
          <div className='left-spoon-container'>
            <div className='left-spoon'/>
            <div className='left-spoon-handle'/>
          </div>
          <div className='middle-handle-container'>
            <div className='middle-spoon-handle'/>
          </div>
          <div className='right-spoon-container'>
            <div className='right-spoon'/>
            <div className='right-spoon-handle'/>
          </div>
        </div>
        
       
        {showHelp &&
          <div className='help' onClick={()=>showHelp()}>Help</div>
        }
        {restExists &&
          <Link to="/vendors/add-vendor" onClick={handleAddVendorClick} className={showHelp ? "add-vendor-button" : "add-vendor-button no-help"}>Add Vendor</Link>
        }
        
        <Menu 
          menuOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          onOpen={() => setMenuOpen(true)}
          menuSelected={menuSelected}
          restName={myRest && myRest.name}
        />
      </div>
    </>
  );
}

export default Header;
/*
 <div className='app-title-outer'>
          <div className='app-title'>Spoon Village</div>
        </div>
        */


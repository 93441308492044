import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { utilMethods } from './util/utilMethods';
//import { firestoreMethods } from './firebase/firestoreMethods';
//import { firestoreRest } from './firebase/RestProvider';
import { firebaseAnalytics } from './firebase/AnalyticsProvider';

import AddIcon from '@mui/icons-material/Add';
//import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import CopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MoreIcon from '@mui/icons-material/ExpandMore';
import LessIcon from '@mui/icons-material/ExpandLess';


//import Tooltip from '@mui/material/Tooltip';



function VendorCardContacts({vendor, defaultCollapse}) {
  const navigate = useNavigate();
  const { trackEvent } = useContext(firebaseAnalytics);


  //const [deleteContact, setDeleteContact] = useState(false);
  const [copy, setCopy] = useState(false);
  const [collapse, setCollapse] = useState(defaultCollapse);
  //const { myRest, dbUser, setRefresh } = useContext(firestoreRest);

  const handleCollapse = (val) => {
    setCollapse(val);
    trackEvent(val ? "contacts_c_closed" : "contacts_c_opened");
  }

  const addContact = () => {
    trackEvent("add_contact");
    navigate(`/vendors/edit-vendor/${vendor.id}/add_contact`);
  }

  const editContact = (contactId) => {
    trackEvent("edit_contact");
    navigate(`/vendors/edit-vendor/${vendor.id}/contacts/${contactId}`)
  }

  // TODO - update this logic with a select to let user choose
  const callContact = (contact) => {
    trackEvent("call_contact");

    if (contact.contact_work) {
      window.open("tel:"+contact.contact_work);
    } else {
      window.open("tel:"+contact.contact_cell);
    }
  }

  const handleCopy = (contact) => {
    setCopy(true);
    trackEvent("copy_contact");

    let strPlain = "";
    /*Object.keys(vendor).forEach((key) => {
      if (!["id", "private", "rec", "not_rec", "history"].includes(key)) {
        strHTML+="<li>"+key + ": "+vendor[key] + "</li>";
        strPlain+=key + ": "+vendor[key] +"\r\n";
      }
    })
    strHTML+="</ul>";
    */

    if (vendor["vendor_name"]) strPlain+= "- " +vendor["vendor_name"] +"\r\n";
    if (vendor["vendor_details"]) strPlain+= "- " +vendor["vendor_details"] +"\r\n";
    if (contact["contact_name"]) strPlain+= "- " +contact["contact_name"] +"\r\n";
    if (contact["contact_cell"]) strPlain+= "- " +contact["contact_cell"] + " (mobile)\r\n";
    if (contact["contact_work"]) strPlain+= "- " +contact["contact_work"] + " (work)\r\n";
    if (contact["contact_email"]) strPlain+= "- " +contact["contact_email"] +"\r\n";

    /*
      // this worked on desktop, but not mobile
        const listener = (e) => {
          e.clipboardData.setData("text/html", strHTML);
          e.clipboardData.setData("text/plain", strPlain);
          e.preventDefault();
        }
      
        //document.addEventListener("copy", listener);
        //document.execCommand("copy");
        //document.removeEventListener("copy", listener); 
    */

    const blobInputPlain = new Blob([strPlain], {type: 'text/plain'});
    const clipboardItemInputPlain = new window.ClipboardItem({'text/plain' : blobInputPlain});
    navigator.clipboard.write([clipboardItemInputPlain]);
    //const blobInput = new Blob([strHTML], {type: 'text/html'});
    //const clipboardItemInput = new window.ClipboardItem({'text/html' : blobInput});
    //navigator.clipboard.write([clipboardItemInput]);
    

    //navigator.clipboard.writeText(strHTML);

  }

  const handleCopyClose = () => {
    setCopy(false);
  }

  const copyAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCopyClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  /*
  const handleContactsDelete = async(contact) => {
    utilMethods.customLog([["ViewVendorContacts -> handleContactsDelete", ""]]);
    setDeleteContact(true);
    await firestoreMethods.removeVendorContact(vendor, contact, dbUser, myRest);
    setRefresh(true);
    //setMode('VIEW');
  }

  const handleDeleteContactClose = () => {
    setDeleteContact(false);
  }

  const deleteContactAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleDeleteContactClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  /*/

  return (collapse
    ? <>
         <div className='v-card-section'>
          <div className='v-card-collapsed-section-header'>
            <div className='v-card-header-row'>
              <div className='v-card-header-label'>Contact</div>
              <div className='v-card-header-value'>
                {utilMethods.getVendorContactPhone(vendor)}
              </div>
            </div>
            {collapse &&
              <MoreIcon className="collapse" onClick={()=>handleCollapse(false)}/>
            }
          </div>
        </div>
      </>
    : <>
      <div className='v-card-section'>
          <div className='v-card-section-header'>
            <div className='view-v-card-title'>Contact Details</div>
            
            <div className='v-card-action' onClick={addContact}>
              <AddIcon className='v-card-icon'/>Add Contact
            </div>
            {!collapse &&
              <LessIcon className="collapse" onClick={()=>handleCollapse(true)}/>
            }
          </div>

        
        {vendor.contacts.map((contact, index) => {
          return (
            <div key={contact.contact_id} className='v-card-contact-row'>
              <div className="v-contact-actions">
                <div className="view-v-action" onClick={() => callContact(contact)}>
                  <div className='circle selected'>
                    <PhoneIcon/>
                  </div>
                  <div className='title'>Call</div>
                </div>
                <div className="view-v-action" onClick={() => handleCopy(contact)}>
                  <div className='circle'>
                    <CopyIcon/>
                  </div>
                  <div className='title'>Copy</div>
                  <Snackbar
                    open={copy}
                    autoHideDuration={6000}
                    onClose={handleCopyClose}
                    message="Vendor information copied to clipboard"
                    action={copyAction}
                  />
                </div>

                {/*
                <div className="view-v-action" onClick={() => navigate(`/vendors/edit-vendor/${vendor.id}/contacts/${contact.contact_id}`)}>
                  <div className='circle'>
                    <EditIcon/>
                  </div>
                  <div className='title'>Edit</div>
                </div>
               
                <div className="view-v-action" onClick={()=>handleContactsDelete(contact)}>
                  <div className='circle'>
                    <DeleteIcon/>
                  </div>
                  <div className='title'>Delete</div>
                </div>
                /*/}
              </div>

              <div>
                <div className='v-contact-name-grouping'>
                 <div className='view-v-contact-name'>{contact["contact_name"] || "n/a"}</div>
                  <div className='v-card-action' onClick={() => editContact(contact.contact_id)}>
                    <EditIcon className='v-card-icon'/>Edit
                  </div>
                </div>
                {contact["contact_cell"] && <div className='view-v-contact-contact'>{contact["contact_cell"]} (mobile)</div>}
                {contact["contact_work"] && <div className='view-v-contact-contact'>{contact["contact_work"]} (work)</div>}
                {contact["contact_email"] && <div className='view-v-contact-contact'>{contact["contact_email"]}</div>}
              </div>

            </div>
            );
        })
        }
        </div>
        {/*
        <Snackbar
          open={deleteContact}
          autoHideDuration={6000}
          onClose={handleDeleteContactClose}
          message="Contact has been removed"
          action={deleteContactAction}
        />
        */}
      
    </>
  );

}


export default VendorCardContacts;

/*
<div key={index} className='view-v-details-row'>
              <div className='view-v-details-row-left-action'>
                <DeleteIcon className='view-v-icon' onClick={()=>handleContactsDelete(contact)}/> 
              </div>
              <div className='view-v-details-row-middle'>
                <div className='view-v-details-row-value'>{contact["contact_name"]}</div>
                {contact["contact_cell"] && <div className='view-v-details-row-value'>{contact["contact_cell"]} (mobile)</div>}
                {contact["contact_work"] && <div className='view-v-details-row-value'>{contact["contact_work"]} (work)</div>}
                <div className='view-v-details-row-value'>{contact["contact_email"]}</div>
              </div>
              <div className="v-card-contact-actions">
                <div className="v-card-contact-action-button" onClick={() => handleCopy(contact)}>
                  <ContentCopyIcon className="v-card-contact-action-icon"/>
                  Copy</div>
                <Snackbar
                  open={copy}
                  autoHideDuration={6000}
                  onClose={handleCopyClose}
                  message="Vendor information copied to clipboard"
                  action={copyAction}
                />
                <div 
                  className="v-card-contact-action-button v-card-contact-action-button-cta"
                  onClick={() => callContact(contact)}
                >
                  <LocalPhoneIcon className="v-card-contact-action-icon"/>Call
                </div>
              </div>
              <ArrowIcon className='view-v-icon' onClick={() => updateContact(contact)}/>
            </div>

            */



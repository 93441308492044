import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { utilMethods } from '../util/utilMethods';




export default function AddVoteNoteDialog({open, handleClose, vote, updateNote}) {
  
  
  const title = (vote > 0) ? "Recommend" : "Do not recommend";
  const labelText = (vote > 0) ? "recommend" : "not recommend";
  const [note, setNote] = useState('');

  const handleAdd = () => {
    //if (note !== '') {
      utilMethods.customLog([["AddVoteNoteDialog -> handleAdd", note]]);
      //await firestoreMethods.addService(service);
      //setRefreshServices(true);
      //addServiceToSelect(service);
      updateNote(note);
    //}
    handleClose();
  }

  const handleFormType = (e) => {
    const {value} = e.target;
    setNote(value);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className='vote-rec-label'>
            {`Please add a quick note why you would ${labelText} this vendor.`}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleFormType}
          />
        </DialogContent>
        <DialogActions>
          <div className='form-button' onClick={handleClose}>Cancel</div>
          <div className='form-button primary' onClick={handleAdd}>Add</div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { firestoreMethods } from '../firebase/firestoreMethods';
import { utilMethods } from '../util/utilMethods';
import { firestoreRest } from '../firebase/RestProvider';

import Tooltip from '@mui/material/Tooltip';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';


function RateForm(props) {
  
  const initFormState = 
    {
      rating: 0,
      note: '',
      reveal: '',
    };

  const [inputs, setInputs] = useState((props.rating) ? props.rating : initFormState);
  const [errors, setErrors] = useState();
  const { myRest, dbUser, setRefresh} = useContext(firestoreRest);

  const labelText = (inputs.rating < 0) ? "not recommend" : "recommend";
  const voteStyle = (inputs.rating > 0) ? 'vote-great link' : ((inputs.rating !== 0) ? 'link vote-poor' : '');
  const thumbUpStyle = 
    ((inputs.rating > 0) ? 'vote-great link ' : '') + 
    'vote-arrow vote-arrow-up rate-form-thumb-space'; 
  const thumbDownStyle = 
    ((inputs.rating < 0) ? 'link vote-poor ' : '') + 
    'vote-arrow vote-arrow-down';

  const navigate = useNavigate();
  const buttonText = (props.type === 'NEW_RATING') 
    ? "Add" 
    : (props.type === 'NEW_VENDOR') 
      ? "Submit" 
      : "Update";


  const handleFormType = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  const handleVote = (vote) => {
    setInputs(prev => ({...prev, rating: vote}))
  }

  const addRating = async() => {
    //console.log("add rating", inputs);
    utilMethods.customLog([["RateForm -> addRating", ""]]);
    await firestoreMethods.addVendorRating(props.vendor, inputs, dbUser, myRest);
    setRefresh(true);
    //navigate(-1);
    navigate(`/vendors/${props.vendor.id}`);
  }

  const updateRating = async() => {
    //console.log("add rating", inputs);
    utilMethods.customLog([["RateForm -> updateRating", ""]]);
    await firestoreMethods.updateVendorRating(props.vendor, inputs, dbUser, myRest);
    setRefresh(true);
    navigate(`/vendors/${props.vendor.id}`);
  }

  const handleSubmit = () => {
    if (inputs.rating === '') {
      setErrors(["Please provide a rating"]);
    } else {
      setErrors(null);
      (props.type === 'NEW_RATING') 
        ? addRating()
        : (props.type === 'NEW_VENDOR') 
          ? props.handleAdd(inputs) 
          : updateRating();
    }
  }

  
  return (
    <>
      
      <div className='view-v-card'>
        <div className='vendor-form'>
          <div className="form-field">
            <div className='form-label'>
              Recommendation            
            </div>
            <div className='rate-form-vote-group'>
              <Tooltip title='Recommend this vendor'>
                <ThumbUp 
                  className={thumbUpStyle}
                  onClick={() => handleVote(1)}
                />
              </Tooltip>
              <Tooltip title='Do not recommend this vendor'>
                <ThumbDown 
                  className={thumbDownStyle}
                  onClick={() => handleVote(-1)}
                />
              </Tooltip>
            </div>
          </div>

          <div className="form-field">
            <div className='form-label'>
              Please add a quick note why you would <span className={voteStyle}>{labelText}</span> this vendor.
            </div>
            <input 
              name="note"
              type="textarea" 
              onChange={handleFormType} 
              value={inputs.note}
              className="form-input"
            />   
          </div>


          {(dbUser.id === process.env.REACT_APP_ADMIN) &&
          <div className="form-field">
            <div className='form-label-admin'>
              ADMIN - Reveal
            </div>
            <input 
              name="reveal"
              type="text" 
              onChange={handleFormType} 
              value={inputs.reveal}
              className="form-input"
            />   
          </div>
         }

          {errors && errors.length > 0 ? errors.map((error,i) => 
            <div className="error-error" key={i+error }>{error}</div> ) : null
          }
          <div className="form-button-group">
            <div className='form-button primary' onClick={handleSubmit}>{buttonText}</div>
            <div className='form-button' onClick={() => props.handleCancel()}>Cancel</div>
          </div>
          
        </div>
      </div>
            
    </>
  );

}


export default RateForm;
//<RatingInput inputs={inputs} handleFormType={handleFormType}/>






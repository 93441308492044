import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { utilMethods } from './util/utilMethods';
import { firestoreMethods } from './firebase/firestoreMethods';
import { firestoreRest } from './firebase/RestProvider';
import { firebaseAuth } from './firebase/AuthProvider';


import Header from './Header';
import AddAdmin from './forms/AddAdmin';
import AddRest from './forms/AddRest';




import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SmileyIcon from '@mui/icons-material/InsertEmoticon';





function MyRest() {
  
  const navigate = useNavigate();
  const { myRest, dbUser, setRefresh} = useContext(firestoreRest);
  const { user, loading } = useContext(firebaseAuth);

  const [mode, setMode] = useState('VIEW');
  const restExists = myRest && Object.keys(myRest).length !== 0;

  const capitalizeFirstLetter = (words) => {
    let arr = words.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    return arr.join(" ");
  }

  const formatDate = (dateStr) => {
    if (dateStr) {
      let d = new Date();
      if (dateStr instanceof Date) {
        d = new Date(dateStr);
      } else {
        d = new Date(dateStr.toDate());
      }
      //date.toDate()).toDateString()
      return (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
    } 
  }


  const handleAddAdmin = async(type, userEmail) => {
    utilMethods.customLog([["MyRest -> addAdmin", `type: ${type}, userEmail: ${userEmail}`]]);
    await firestoreMethods.addAdmin(type, userEmail, myRest.id, dbUser); 
    setRefresh(true);
    setMode("VIEW");
  }

  const removeAdmin = async(type, user) => {
    utilMethods.customLog([["MyRest -> removeAdmin", `type: ${type}, user: ${user}`]]);
    await firestoreMethods.removeAdmin(type, user, myRest, dbUser);
    setRefresh(true);
  }

  // redirect Use Effect
  useEffect(() => {
    utilMethods.customLog([["MyRest -> UseEffect -> Route", `loading: ${loading}, user: ${user}`]]);
    if (!loading) {
      if (!user) navigate("/");
    }
  }, [user, loading, navigate]);

  if (!myRest || !dbUser) return null;
  return (

    <>
      <Header/>
      { (mode === 'VIEW' && restExists) && 
        <>
          <div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={()=>navigate(-1)}/>
            <h2>{myRest.name}</h2>
          </div>  
          {(myRest.admins.find(admin => admin.id === dbUser.id)) && 
            <div className='view-v-card'>
              <div className='view-v-card-header'>
                <div className='view-v-card-title'>Admins</div>
                <div className='view-v-card-action' onClick={() => setMode('ADD_ADMIN')}>
                  <AddIcon className='view-v-icon'/>Add Admin
                </div>
              </div>
              <div className='view-v-card-instruction'>
                Super user.  Ability to add and remove other Admins and Managers.  Ability to view, add and remove vendors.
              </div>
              {myRest.admins.map((user) => {
                  return (
                    <div key={user.email} className='view-v-details-row'>
                      <div  className='view-v-details-row-left-action'>
                        {
                          (dbUser.id !== user.id) 
                          ?
                            <DeleteIcon className='view-v-icon' onClick={()=>removeAdmin("Admin", user)}/>
                          :
                            <SmileyIcon/>
                        }
                      </div>
                      <div className='view-v-details-row-left'>
                        <div className='view-v-details-row-label-rest'>
                          {(user.name) ? capitalizeFirstLetter(user.name) : user.email }
                        </div>
                        <div className='view-v-details-row-value-rest'>
                          {(user.accepted) ? "Verified" : "Not Verified (User has not yet logged in)" }
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          }

          {(myRest.admins.find(admin => admin.id === dbUser.id)) && 
            <div className='view-v-card'>
              <div className='view-v-card-header'>
                <div className='view-v-card-title'>Managers</div>
                <div className='view-v-card-action' onClick={() => setMode("ADD_MANAGER")}>
                  <AddIcon className='view-v-icon'/>Add Manager
                </div>
              </div>
              <div className='view-v-card-instruction'>
                Ability to view, add and remove vendors.
              </div>
              {(myRest.managers.length > 0) && myRest.managers.map((user) => {
                  return (
                     <div key={user.email} className='view-v-details-row'>
                      <div  className='view-v-details-row-left-action'>
                        <DeleteIcon className='view-v-icon' onClick={()=>removeAdmin("Manager", user)}/>
                      </div>
                      <div className='view-v-details-row-left'>
                        <div className='view-v-details-row-label-rest'>
                          {(user.name) ? capitalizeFirstLetter(user.name) : user.email }
                        </div>
                        <div className='view-v-details-row-value-rest'>
                          {(user.accepted) ? "Verified" : "Not Verified (User has not yet logged in)" }
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          }

          <div className='view-v-card'>
            <div className='view-v-card-header'>
              <div className='view-v-card-title'>History</div>
            </div>
            {myRest.history.map((item, index) => {
              return (
                <div key={index} className='view-v-details-row'>
                  <div className='view-v-details-row-left'>
                    <div className='view-v-details-row-history'>
                      {item.op}, by {item.user_email}, on {formatDate(item.date)}
                    </div>
                  </div>
                </div>
                );
            })
            }
          </div>

        </>
      }
      { (mode === 'ADD_ADMIN') && 
        <>
          <div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={()=>setMode('VIEW')}/>
            <h2>Add Admin</h2>
          </div>
          <AddAdmin
            type="Admin"
            handleAddAdmin={handleAddAdmin}
            handleAdminCancel={() => setMode('VIEW')}
          />
          <div/>
        </>
      }
      { (mode === 'ADD_MANAGER') && 
        <>
          <div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={()=>setMode('VIEW')}/>
            <h2>Add Manager</h2>
          </div>
          <AddAdmin
            type="Manager"
            handleAddAdmin={handleAddAdmin}
            handleAdminCancel={() => setMode('VIEW')}
          />
        </>
      }
      { (!restExists) && 
        <AddRest 
          dbUser={dbUser}
          setRefresh={setRefresh}
        />
      }
    </>
      
  );

}


export default MyRest;

/*

<div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={() => navigate(-1)}/>
            <h2>Add Rest</h2>
          </div>
          
<div className='view-v-card'>
            <div className='view-v-card-header'>
              <div className='view-v-card-title'>Basic Details</div>
            </div>
            <div className='view-v-details-row' onClick={() => setMode('EDIT_NAME')}>
              <div className='view-v-details-row-left'>
                <div className='view-v-details-row-label'>Name</div>
                <div className='view-v-details-row-value'>
                  {myRest.name}
                </div>
              </div>
              <ArrowIcon className='view-v-icon'/>
            </div> 
          </div>

          { (mode === 'EDIT_NAME') && 
        <>
          <div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={()=>setMode('VIEW')}/>
            <h2>Edit Restaurant Name</h2>
          </div>
          <div/>
        </>
      }

          */

import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { utilMethods } from './util/utilMethods';
import { firestoreMethods } from './firebase/firestoreMethods';
import { firebaseAuth } from './firebase/AuthProvider';
import { firestoreRest } from './firebase/RestProvider';
import { firebaseAnalytics } from './firebase/AnalyticsProvider';

import Header from './Header';
import VendorsShow from './VendorsShow';
import AddRest from './forms/AddRest';
import Help from './Help';

//import spoons from './assets/green_spoons.png';


function Vendors() {
  //const { pathname } = useResolvedPath();
  //console.log("pathname:", pathname);
  /*const match = useMatch('/vendors/:lastPart')
  const value = match?.props.lastPart;
  if (value) {
      // do some stuff here
    console.log("value", value);
  }*/

  const navigate = useNavigate();

  const { user, loading } = useContext(firebaseAuth);
  const { myRest, dbUser, setRefresh } = useContext(firestoreRest);
  const { trackEvent } = useContext(firebaseAnalytics);


  const [allVendors, setAllVendors] = useState();
  //const [restVendors, setRestVendors] = useState();


  const initSearchState = {search: ''};
  const restExists = myRest && Object.keys(myRest).length !== 0;

  const [inputs, setInputs] = useState(initSearchState);
  const [onListVendors, setOnListVendors] = useState();
  const [communityVendors, setCommunityVendors] = useState();
  const [help, setHelp] = useState(false);
  

  const handleSearch = (e) => {
    
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
    
    // TODO: make this better, log the search entry
    // maybe by adding to URL?
    trackEvent("search"+value.length);
    // update tutorial setting
    /*
    if (dbUser && !Object.keys(dbUser).includes("welcome")) {
      setHelp(false);
      await firestoreMethods.updateUser(dbUser, "welcome", true, setRefresh);
    }
    */
  }

  const handleShowHelp = () => {
    trackEvent("help");
    setHelp(true);
  }

  const handleHelpCancel = async () => {
    if (!dbUser.welcome) {
      await firestoreMethods.updateUser(dbUser, "welcome", true);
      setRefresh(true);
    }
    setHelp(false);
  }

  // filter vendor list based on user inputs
  useEffect(() => {
    utilMethods.customLog([["Vendors -> UseEffect-> Filter Vendors->Enter", inputs] ]);
    

    let active = true;

    if (!allVendors || !myRest) return;
    if (active) {
      if (inputs.search !== "") {
        // return values will be:
          // onList - null if nothing on list, or empty list, or filtered list
          // community - empty or filtered list
        utilMethods.filterVendors(inputs, allVendors, myRest, setOnListVendors, setCommunityVendors);  
      } else {
        // return values will be:
          // onList - null if nothing on list, or all onList vendors
          // community - all non-list venodrs
        if (allVendors && myRest && myRest.vendors && myRest.vendors.length > 0) {
          setCommunityVendors(
            utilMethods.sortByCustomName(
              allVendors.filter((vendor) => !myRest.vendors.includes(vendor.id))
            )
          );
          setOnListVendors(
            utilMethods.sortByCustomName(
              allVendors.filter((vendor) => myRest.vendors.includes(vendor.id))
            )
          );

        } else {
          setCommunityVendors(
            utilMethods.sortByCustomName(allVendors)
          );
        }
        
        //setOnListVendors(tmpVendors ? utilMethods.sortByCustomName(tmpVendors) : null);
      } 
    }
    
    return () => {
      active = false;
    };
  }, [inputs, allVendors, myRest]);

  // fetch Vendors Use Effect
  useEffect(() => {
    utilMethods.customLog([["Vendors -> UseEffect -> Fetch Vendors -> Enter", `myRest ${myRest && Object.keys(myRest)} vendors: ${myRest && myRest.vendors}`]]);
    
    if (!myRest) return;

    let active = true;

    const fetchVendors = async () => {
      utilMethods.customLog([["Vendors -> UseEffect -> Fetch Vendors -> Fetch", `myRest vendors: ${myRest && myRest.vendors}`]]);

      const newData = await firestoreMethods.getAllVendors(myRest);
      if (active){
        //setRestVendors(newData.restVendors);
        setAllVendors(newData);
      }
    };  

    fetchVendors();
    
    return () => {
      active = false;
    };
  }, [myRest]);

  // redirect Use Effect
  useEffect(() => {
    utilMethods.customLog([["Vendors -> UseEffect -> Route", `loading: ${loading}, user: ${user}`]]);
    if (!loading) {
      if (!user) navigate("/");
      //if (!restExists && type === "restaurant") navigate("add-restaurant");
    }
  }, [user, loading, navigate]);

  //if (!communityVendors) return null;
  if (!dbUser || !myRest) return null;

  return ((!restExists) 
      ?
        <>
          <Header/>
          <AddRest 
            dbUser={dbUser}
            setRefresh={setRefresh}
          />
        </>
      :     
        
        <>
          <Header showHelp={handleShowHelp}/>

          <div className='search-container'>
            <input autoFocus
                name="search" 
                type="search" 
                onChange={handleSearch} 
                placeholder="Search by Service, Vendor or Contact" 
                value={inputs.search} 
                className="my-search"
              />   
          </div>
          
          {(help || !dbUser.welcome)
          
          ? <Help 
              handleCancel={handleHelpCancel}/>
          :
            <>
             <VendorsShow
                type="ON_LIST"
                vendors={onListVendors} 
              />
              <VendorsShow
                type="COMMUNITY"
                vendors={communityVendors} 
              />
            </>
          }
        </>
  );

}


export default Vendors;

import { useState } from 'react';

import { utilMethods } from '../util/utilMethods';


function AddAdmin({type, handleAddAdmin, handleAdminCancel}) {
  
  const [userEmail, setUserEmail] = useState('');
  const [emailEM, setEmailEM] = useState();
  const [generalEM, setGeneralEM] = useState();
  const [errorStyle, setErrorStyle] = useState('error-warning');



  const handleUserEmail = (e) => {
    const {value} = e.target
    setUserEmail(value);

    if (!isValidEmail(value)) {
      setEmailEM('Email should be like: xxxx@xxxx.com');
    } else {
      setEmailEM(null);
    }
    
  }

  const handleAdminAdd = async() => {
    utilMethods.customLog([["AddAdmin -> handleAdminAdd", ""]]);
    
    if (emailEM) {
      setErrorStyle('error-error');
     } else {
      // if any fields are blank
      if (userEmail === '') {
        setErrorStyle('error-error');
        setGeneralEM('Email should not be blank');
      } else {
        //submit
        setErrorStyle('error-warning');
        setGeneralEM(null);
        handleAddAdmin(type, userEmail);
        //
      }
     }
  }

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
      
      <div className='view-v-card'>
        <div className='vendor-form'>

          <div className="form-field">
            <div className='form-label'>Email</div>
            <input 
              name="userEmail"
              type="text" 
              onChange={handleUserEmail} 
              value={userEmail}
              className="form-input"
            />  
            {emailEM && <span className={errorStyle}>{emailEM}</span>}
          </div>
          {generalEM && <div><span className={errorStyle}>{generalEM}</span></div>}

          <div className="form-button-group">
            <div className='form-button primary' onClick={handleAdminAdd}>Add</div>
            <div className='form-button' onClick={handleAdminCancel}>Cancel</div> 
          </div>
        
        </div>
      </div>
      
      
  );

}


export default AddAdmin;

import { useState } from 'react';
//import { utilMethods } from './util/utilMethods';

import ServicesSelect from './ServicesSelect';
//import Rating from './Rating';

function VendorDetailsForm(props) {
  
  const initFormState = 
    {
      private: (props.vendor && props.vendor.private) ? props.vendor.private : false,
      vendor_name: (props.vendor && props.vendor.vendor_name) ? props.vendor.vendor_name :'',  
      vendor_site: (props.vendor && props.vendor.vendor_site) ? props.vendor.vendor_site :'',  
      services: (props.vendor && props.vendor.services) ? props.vendor.services : [],
      history: (props.vendor && props.vendor.history) ? props.vendor.history : [],
      contacts: (props.vendor && props.vendor.contacts) ? props.vendor.contacts : [],
      ratings: (props.vendor && props.vendor.ratings) ? props.vendor.ratings : [],
    };

  const [inputs, setInputs] = useState(initFormState);
  const [errors, setErrors] = useState();


  const handleFormType = (e) => {
    const {name, value} = e.target;
    setInputs(prev => ({...prev, [name]: value}))
  }

  const handlePrivateToggle = (e) => {
    const {name} = e.target
    setInputs(prev => ({...prev, [name]: !inputs.private}))
  }

  const handleSubmit = () => {
    if (inputs.services.length === 0) {
      setErrors(["At least one service should be provided"]);
    } else {
      setErrors(null);
      if (props.vendor) {
        props.handleUpdate(inputs);
      } else {
        props.handleAdd(inputs);

      }
    }
  }

  return (
      <div className='view-v-card'>
        <div className='vendor-form'>

          <div className="form-field">
            <div className='form-label'>Vendor Name</div>
            <input 
              name="vendor_name"
              type="text" 
              onChange={handleFormType} 
              value={inputs.vendor_name}
              className="form-input"
            />   
          </div>

          <div className="form-field">
            <div className='form-label'>Vendor Site</div>
            <input 
              name="vendor_site"
              type="text" 
              onChange={handleFormType} 
              value={inputs.vendor_site}
              className="form-input"
            />   
          </div>

          <div className="form-field">
            <ServicesSelect 
              inputs={inputs} 
              setInputs={setInputs} 
              vendorServices={null}
            />
          </div>
          { (!props.vendor || props.vendor.private === true) &&
            <div className="form-field">
              <div className='form-label'>Private</div>
              <div className='form-label-group'>
                <input
                  name='private'
                  type="checkbox"
                  checked={inputs.private}
                  onChange={handlePrivateToggle}
                  className='form-input'
                />
                <div className='form-privacy-label'>
                  Visible only to  
                  <span className='view-v-contact-name'> {props.myRest.name}</span>
                </div>
              </div>
            </div>
          }

          {errors && errors.length > 0 ? errors.map((error,i) => 
            <div className="error-error" key={i+error }>{error}</div> ) : null
          }
          
          <div className="form-button-group">
            {(props.vendor) 
            ? <div className='form-button primary' onClick={handleSubmit}>Update</div>
            : <div className='form-button primary' onClick={handleSubmit}>Continue</div>
            }  
            <div className='form-button' onClick={() => props.handleCancel()}>Cancel</div> 
          </div>
          
        </div>
      </div>
  );

}


export default VendorDetailsForm;

/*
{(!inputs.private) &&
            <div>
              <p>Please note, once a vendor is shared with the community, it cannot be 'unshared'. </p>
            </div>
            }


            <div className="form-field">
            <span>Services:</span>
            <select 
              name="services" 
              onChange={handleFormType} 
              value={inputs.services}
              className="form-input"
              multiple={true}
            >
            { inputs.services && selectBoxes.map((item, index) => {
              return (
                  <option 
                    key={index}
                    value={item} 
                  >{item}</option>
              );
             })}
            </select>
          </div>    

            */

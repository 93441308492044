import { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

import { firebaseAuth } from './firebase/AuthProvider';
import { firebaseAnalytics } from './firebase/AnalyticsProvider';

import { utilMethods } from './util/utilMethods';




function Join() {
  const { user, loading, join, signInWithGoogle } = useContext(firebaseAuth);
  const { trackEvent } = useContext(firebaseAnalytics);
  const navigate = useNavigate();
  const [errors, setErrors] = useState();

  const initFormState = 
    {
      email: '',
      password: '',
    };
  const [inputs, setInputs] = useState(initFormState);
 

  const handleChange = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  const handleSignInWithGoogle = () => {
    trackEvent("join_google");
    signInWithGoogle(setErrors);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Error check
    if (inputs.email==='' || inputs.password === '') {
      setErrors(["Email or Password cannot be blank"])
    } else if (!utilMethods.isValidEmail(inputs.email)) {
      setErrors(['Email should be like: xxxx@xxxx.com']);
    } else {
      //submit and join
      setErrors(null);
      trackEvent("join_email");
      await join(inputs, setErrors);
    }
  }

  useEffect(() => {
    utilMethods.customLog([["Login -> UseEffect -> loading", loading], ["Login -> UseEffect -> user", user] ]);

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/vendors");
  }, [user, loading, navigate]);

  return (
    <div className='landing-outer'>
      <div className='landing-box'>
        <div className='login-form'>
          <div className='landing-title'>
            Spoon Village
          </div>

            <div className="form-field">
              <div className='form-label'>Email</div>
           
              <input name="email" type="email" onChange={handleChange} className="form-input" placeholder="Enter email" value={inputs.email} />
            </div>
            <div className="form-field">
            <div className='form-label'>Password</div>
           
            <input name="password" type="password" onChange={handleChange} className="form-input" placeholder="Enter password" value={inputs.password} />
            </div>
            <div className="login-button-group">
              {errors && errors.length > 0 ? errors.map((error,i) => 
                <div className="error-error" key={i+error }>{error}</div> ) : null
              }
              <div className="form-button primary login-button" onClick={handleSubmit}>          
                Join
              </div>
              <div className='login-message'>
                Already have an account?&nbsp;
                <Link className='link' to="/login">Login</Link>
              </div>
              <div className="login-or">
                <span className="login-or-text">OR</span>
              </div>
              <div className="sign-in-google" type="button" onClick={handleSignInWithGoogle}>
                <svg className='sign-in-google-svg' viewBox="0 0 48 48" preserveAspectRatio="xMinYMin meet" focusable="false" aria-hidden="true">
                  <title>Google Logo</title>
                  <clipPath id="g">
                    <path d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"></path>
                  </clipPath>
                  <g clipPath="url(#g)">
                    <path fill="#FBBC05" d="M0 37V11l17 13z"></path>
                    <path fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"></path>
                    <path fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"></path>
                    <path fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"></path>
                  </g>
                </svg>
                Continue with Google
              </div>
            </div>
            
          
            {/*<div className='login-message'>
              By using Spoon Village, you agree to our&nbsp; 
                <Link className='link' to="/privacy">Privacy Policy</Link>&nbsp;and our&nbsp;
                <Link className='link' to="/terms">Terms of Service</Link>.
            </div>*/}
        </div>
      </div>
    </div>
  );
}

export default Join;

import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { firebaseAuth } from './firebase/AuthProvider';
import { utilMethods } from './util/utilMethods';




function Landing() {
  const { user, loading } = useContext(firebaseAuth);
  //const [invalidCode, setInvalidCode] = useState();
  //const [code, setCode] = useState('');


  const navigate = useNavigate();

  /*
  const handleFormType = (e) => {
    const { value} = e.target;
    setCode(value);
  }


  const checkCode = () => {
    if (code === '') {
      setInvalidCode("Please provide a code.");
    } else if (code.toLowerCase() !== 'sv23') {
      setInvalidCode("This code is invalid.  Please provide a valid code.");
    } else {
      navigate("/login");
    }
  }

  */

  useEffect(() => {
    utilMethods.customLog([["Landing -> UseEffect -> loading", loading], ["Landing -> UseEffect -> user", user] ]);

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/vendors");
  }, [user, loading, navigate]);

  return (
    <div className='landing-outer'>
      <div className='landing-header'>
        <div className='landing-header-link'>faq</div>
        <a className='landing-header-link' href="mailto:contact@spoonvillage.com">contact</a>
      </div>
      <div className='landing-title'>
        Spoon Village
      </div>
      <div className='landing-brief'>
        Use reliable and cost-friendly vendors that your peers recommend
      </div>
      <div className="landing-button-group">
        <div className="form-button primary login-button" onClick={() => navigate("/join")}>          
          Sign Up
        </div>
        <div className="form-button login-button" onClick={() => navigate("/login")}>          
          Login
        </div>
      </div>
      
    </div>
  );
}

export default Landing;

/*

in landing b utton group.  Took it away since now we don't have an invite code
<div className='login-message'>
          Interested to hear our updates?&nbsp;
          <Link className='link' to="/waitlist">Join our waitlist</Link>.
        </div>

<div className='landing-invite-code-outer'>
          <input 
            name="code"
            type="text" 
            onChange={handleFormType} 
            placeholder="Invite Code"
            value={code}
            className="landing-invite-code"
          />
          <div className='landing-submit-invite' onClick={checkCode}>
           Submit
          </div>
      </div>

{invalidCode && 
        <div className='error-error'>{invalidCode}</div>
      }

<div className='landing-brief'>For now, use code: SV23</div>
      

      <div className='landing-box'>
        <div className='app-title landing-pad'>Spoon Village</div>
        <div className='landing-pad'>A private community for Brooklyn's Restaurants</div>
        <div className='landing-pad'>
          <div className='sv-button' onClick={() => navigate("/login")}>
            Spoof Invite Code
          </div>
        </div>
      </div>/*/

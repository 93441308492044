import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LogoutIcon from '@mui/icons-material/Logout';
import MyRestaurantIcon from '@mui/icons-material/Storefront';
//import MyAccountIcon from '@mui/icons-material/PermIdentity';



function Menu({menuOpen, onClose, onOpen, menuSelected, restName}) {

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <div className='menu-header'>
        <div className='app-title'>Spoon Village</div>
      </div>
      <Divider />
      <List>
          
        <ListItem key='my-rest' disablePadding onClick={()=>menuSelected("RESTAURANT")}>
          <ListItemButton>
            <ListItemIcon>
              <MyRestaurantIcon/>
            </ListItemIcon>
            <ListItemText primary={restName || 'My Venue'} />
          </ListItemButton>
        </ListItem>

        <ListItem key='logout' disablePadding onClick={()=>menuSelected("LOGOUT")}>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon/>
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </ListItem>
      
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor='left'
      open={menuOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      {list('left')}
    </SwipeableDrawer>
       
  );
}

export default Menu;

/*
<ListItem key='my-account' disablePadding onClick={()=>menuSelected("ACCOUNT")}>
            <ListItemButton>
              <ListItemIcon>
                <MyAccountIcon/>
              </ListItemIcon>
              <ListItemText primary='My Account' />
            </ListItemButton>
          </ListItem>
          /*/
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { firebaseAuth } from '../firebase/AuthProvider';
import { firestoreMethods } from '../firebase/firestoreMethods';
import { firestoreRest } from '../firebase/RestProvider';
import { utilMethods } from '../util/utilMethods';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import VendorDetailsForm from './VendorDetailsForm';
import VendorContactsForm from './VendorContactsForm';
import Header from '../Header';

function EditVendor() {
  const { vendorId } = useParams();
  const { type } = useParams();
  const { contactId } = useParams();

  const navigate = useNavigate();
  
  const { user, loading } = useContext(firebaseAuth);
  const { myRest, dbUser, setRefresh } = useContext(firestoreRest);

  const [vendor, setVendor] = useState();
  const [contact, setContact] = useState();

  const handleUpdate = async(inputs) => {
    utilMethods.customLog([["EditVendor -> handleUpdate", ""]]);
    await firestoreMethods.updateVendor(vendor, inputs, dbUser, myRest);
    setRefresh(true);
    navigate(`/vendors/${vendor.id}`);
  }

  const handleContactsUpdate = async(contactInputs) => {
    utilMethods.customLog([["EditVendor -> handleContactsUpdate", ""]]);
    await firestoreMethods.updateVendorContact(vendor, contactInputs, dbUser, myRest);
    setRefresh(true);
    navigate(`/vendors/${vendor.id}`);
  }

  const handleContactsAdd = async(contactInputs) => {
    utilMethods.customLog([["EditVendor -> handleContactsAdd", ""]]);
    await firestoreMethods.addVendorContact(vendor, contactInputs, dbUser, myRest);
    setRefresh(true);
    navigate(`/vendors/${vendor.id}`);
  }

  // fetch Vendor Use Effect
  useEffect(() => {
    utilMethods.customLog([["EditVendor -> UseEffect -> Fetch Vendor", `vendorId: ${vendorId}`]]);

    if (!vendorId) return;

    let active = true;

    const fetchVendor = async () => {
      utilMethods.customLog([["EditVendor -> fetchVendor", ""]]);
      const newData = await firestoreMethods.getVendor(vendorId);
      if (active){
        setVendor(newData);
        setRefresh(false);
        setContact(newData.contacts.find((contact) => contact.contact_id === contactId));
      }
    };  

    fetchVendor();
    
    return () => {
      active = false;
    };
  }, [vendorId, setRefresh, contactId]);

  // redirect Use Effect
  useEffect(() => {
    utilMethods.customLog([["EditVendor -> UseEffect -> Route", `loading: ${loading}, user: ${user}`]]);
    if (!loading) {
      if (!user) navigate("/");
    }
  }, [user, loading, navigate]);

  if (!vendor || !myRest) return null;


  return (
    <>
      <Header/>
      
      { (type === 'details') &&
        <>
          <div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={()=>navigate(-1)}/>
            <h2>{utilMethods.getVendorName(vendor)}</h2>
          </div>
          <VendorDetailsForm
            vendor={vendor}
            myRest={myRest}
            handleUpdate={handleUpdate}
            handleCancel={() => navigate(`/vendors/${vendor.id}`)}
          />
        </>
      }
      { (type === 'contacts') &&
        <>
          <div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={()=>navigate(-1)}/>
            <h2>{utilMethods.getVendorName(vendor)}</h2>
          </div>
          {contact && 
            <VendorContactsForm
              contact={contact}
              handleUpdate={handleContactsUpdate}
              handleCancel={()=>navigate(`/vendors/${vendor.id}`)}
              label="Update"
            />
          }
        </>
      }
      { (type === 'add_contact') &&
        <>
          <div className='back-header'>
            <ArrowBackIcon className="back-icon" onClick={()=>navigate(-1)}/>
            <h2>{utilMethods.getVendorName(vendor)} - Add Contact</h2>
          </div>
          <VendorContactsForm
            handleAdd={handleContactsAdd}
            handleCancel={()=>navigate(`/vendors/${vendor.id}`)}
            label="Add"
          />
        </>
      }
      
    </>
  );

}


export default EditVendor;



import { createContext, useContext, useEffect } from 'react';
import { logEvent, setUserId } from "firebase/analytics";

import analytics from "./firebaseApp";
import { firebaseAuth } from './AuthProvider';
import { utilMethods } from '../util/utilMethods';

export const firebaseAnalytics = createContext();

const AnalyticsProvider = (props) => {
  
  const { user } = useContext(firebaseAuth);

  const trackEvent = (content) => {
    utilMethods.customLog([["AnalyticsProvider -> trackEvent", ``]]);
    
    // don't log ADMIN interactions
    if (!user || (user && user.uid !== process.env.REACT_APP_ADMIN)) {
      logEvent(analytics, 'select_content', {
        content_type: content,
        content_id: content,
      });
    }
  }

  // setUser once user is logged in
  useEffect(() => {
    utilMethods.customLog([["AnalyticsProvider -> user UseEffect -> Enter", `user: ${user}`]]);

    if (!user) {
      setUserId(analytics, '');
      return;
    }

    let active = true;

    const setAnalyticsUser = () => {
      if (active) {
        utilMethods.customLog([["AnalyticsProvider -> user UseEffect -> Setting User", `user: ${user.uid}`]]);
        setUserId(analytics, user.uid);
      }
    };

    if (user) {
      setAnalyticsUser();
    }
    return () => {
      active = false;
    };
  }, [user]); 

  return (
    <firebaseAnalytics.Provider
    value={{
      trackEvent,
    }}>
      {props.children}
    </firebaseAnalytics.Provider>
    
  );
};

export default AnalyticsProvider;



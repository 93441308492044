//import { utilMethods } from './util/utilMethods';

import VendorCardHeader from './VendorCardHeader';
import VendorCardDetails from './VendorCardDetails';
import VendorCardContacts from './VendorCardContacts';
import VendorCardHistory from './VendorCardHistory';

//import VendorCardRatings from './VendorCardRatings';

function VendorCard({vendor, onList, defaultCollapse}) {

  return (
    <>
      <div className={onList ? 'v-card v-card-on-list' : 'v-card'}>
        
        <VendorCardHeader 
          vendor={vendor}
        />
        
        <VendorCardDetails
          vendor={vendor}
          defaultCollapse={defaultCollapse}
        />
    
        <VendorCardContacts 
          vendor={vendor}
          defaultCollapse={defaultCollapse}
        />

        <VendorCardHistory 
          vendor={vendor}
          defaultCollapse={defaultCollapse}
        />
{/*
        <VendorCardRatings 
          vendor={vendor}
          defaultCollapse={defaultCollapse}
        />


        <div className='v-card-section'>
          <div className='v-card-history'>
            {utilMethods.timeSince(lastHistory.date)}: {lastHistory.op} by {lastHistory.rest} 
          </div>
        </div>
/*/}
      </div>
                      
    </>
  );

}

export default VendorCard;


      

                






import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { firebaseAuth } from '../firebase/AuthProvider';
import { firestoreMethods } from '../firebase/firestoreMethods';
import { utilMethods } from '../util/utilMethods';

import Header from '../Header';
import RateForm from './RateForm';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function RateVendor() {
  const { vendorId } = useParams();
  const { ratingId } = useParams();

  const navigate = useNavigate();
  const { user, loading } = useContext(firebaseAuth);
  
  const [vendor, setVendor] = useState();

  // fetch Vendor Use Effect
  useEffect(() => {
    utilMethods.customLog([["RateVendor -> UseEffect -> Fetch Vendor", `vendorId: ${vendorId}`]]);

    if (!vendorId) return;

    let active = true;

    const fetchVendor = async () => {
      utilMethods.customLog([["RateVendor -> fetchVendor", ""]]);
      const newData = await firestoreMethods.getVendor(vendorId);
      if (active){
        setVendor(newData);
        /*
        // set inputs to the rating if user is editing a rating -> now passed as prop
        // if I go back to rating services, need below code
        if (ratingId && newData.ratings && newData.ratings.length > 0) {
          setInputs(utilMethods.getRatingToEdit(newData.ratings, ratingId));
        } else if (newData.services && newData.services.length > 0) {
          setInputs(prev => ({...prev, services: [newData.services[0]]}));
        }
        */
      }
    };  

    fetchVendor();
    
    return () => {
      active = false;
    };
  }, [vendorId, ratingId]);

  // redirect Use Effect
  useEffect(() => {
    utilMethods.customLog([["RateVendor -> UseEffect -> Route", `loading: ${loading}, user: ${user}`]]);
    if (!loading) {
      if (!user) navigate("/");
    }
  }, [user, loading, navigate]);

  if (!vendor) return null;
  
  return (
    <>
      <Header/>
      <div className='back-header'>
        <ArrowBackIcon className="back-icon" onClick={()=>navigate(`/vendors/${vendor.id}`)}/>
        <h2>{(ratingId) ? "Update" : "Add"} Your Rating</h2>
      </div>
      <RateForm
        rating={ratingId && vendor && utilMethods.getRatingToEdit(vendor.ratings, ratingId)}
        type={(ratingId) ? "UPDATE" : "NEW_RATING"}
        handleCancel={() => navigate(`/vendors/${vendor.id}`)}
        vendor={vendor}
      />    
    </>
  );

}


export default RateVendor;

import { useState } from 'react';
import { utilMethods } from '../util/utilMethods';


function VendorContactsForm(props) {
  
  const initFormContactState = 
    {
      contact_name: (props.contact && props.contact.contact_name) ? props.contact.contact_name : '',
      contact_cell: (props.contact && props.contact.contact_cell) ? props.contact.contact_cell : '',
      contact_work: (props.contact && props.contact.contact_work) ? props.contact.contact_work : '',
      contact_email: (props.contact && props.contact.contact_email)? props.contact.contact_email : '',
      contact_notes: (props.contact && props.contact.contact_notes) ? props.contact.contact_notes : '',
      contact_id: (props.contact && props.contact.contact_id) ? props.contact.contact_id : Math.floor(Math.random() * 100000000).toString(),
    };

  const [contactInputs, setContactInputs] = useState(initFormContactState);
  const [emailEM, setEmailEM] = useState();
  const [cellEM, setCellEM] = useState();
  const [workEM, setWorkEM] = useState();
  const [generalEM, setGeneralEM] = useState();
  const [errorStyle, setErrorStyle] = useState('error-warning');


  const isValidPhone = (phone) => {
    return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone);
  }

  const handleFormContactType = (e) => {
    const {name, value} = e.target;

    // update phone number to be formatted correctly, as the user types it
    if (name === 'contact_work' || name === 'contact_cell') {
      let tmpPhone='';
      const input = value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
      const areaCode = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);
      if(input.length > 6){tmpPhone = `${areaCode}.${middle}.${last}`;}
      else if(input.length > 3){tmpPhone = `${areaCode}.${middle}`;}
      else if(input.length > 0){tmpPhone = `${areaCode}`;}
      setContactInputs(prev => ({...prev, [name]: tmpPhone}));
    } else {
      setContactInputs(prev => ({...prev, [name]: value}));
    }

    if (name === 'contact_work') {
      if (!isValidPhone(value) && value !== '') {
        setWorkEM('Work Phone should be like: 123.123.1234');
      } else {
        setWorkEM(null);
      }
    }

    if (name === 'contact_cell' && value !== '') {
      if (!isValidPhone(value)) {
        setCellEM('Cell Phone should be like: 123.123.1234');
      } else {
        setCellEM(null);
      }
    }

    if (name === 'contact_email' && value !== '') {
      if (!utilMethods.isValidEmail(value)) {
        setEmailEM('Email should be like: xxxx@xxxx.com');
      } else {
        setEmailEM(null);
      }
    }
  }

  const handleSubmit = () => {
   // check errors 
   
   if (workEM || emailEM || cellEM) {
    setErrorStyle('error-error');
   } else {
    // if any fields are blank
    if (contactInputs.contact_cell === '' && contactInputs.contact_work === '' && contactInputs.contact_email === '') {
      setErrorStyle('error-error');
      setGeneralEM('Phone or email should be provided');
    } else {
      //submit
      setErrorStyle('error-warning');
      setGeneralEM(null);
      (props.contact) ? props.handleUpdate(contactInputs) : props.handleAdd(contactInputs);
    }
   }
  }


  return (
    <div className='view-v-card'>
      <div className='vendor-form'>
        <div className="form-field">
          <div className='form-label'>Contact Name</div>
          <input 
            name="contact_name"
            type="text" 
            onChange={handleFormContactType} 
            value={contactInputs.contact_name}
            className="form-input"
          />   
        </div>
        <div className="form-field">
          <div className='form-label'>Work Phone</div>
          <input 
            name="contact_work"
            type="text" 
            onChange={handleFormContactType} 
            value={contactInputs.contact_work}
            className="form-input"
          />
          {workEM && <span className={errorStyle}>{workEM}</span>}
        </div>
        <div className="form-field">
          <div className='form-label'>Cell Phone</div>
          <input 
            name="contact_cell"
            type="text" 
            onChange={handleFormContactType} 
            value={contactInputs.contact_cell}
            className="form-input"
          />   
          {cellEM && <span className={errorStyle}>{cellEM}</span>}
        </div>
        <div className="form-field">
          <div className='form-label'>Email</div>
          <input 
            name="contact_email"
            type="text" 
            onChange={handleFormContactType} 
            value={contactInputs.contact_email}
            className="form-input"
          />  
          {emailEM && <span className={errorStyle}>{emailEM}</span>}
   
        </div>
        {generalEM && <div><span className={errorStyle}>{generalEM}</span></div>}
        <div className="form-button-group">
          <div className='form-button primary' onClick={handleSubmit}>{props.label}</div> 
          <div className='form-button' onClick={() => props.handleCancel()}>Cancel</div>
        </div>
      </div>
    </div>
  );

}


export default VendorContactsForm;

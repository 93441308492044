import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./Login";
import Join from "./Join";
import Landing from "./Landing";
import Vendors from "./Vendors";
import VendorView from "./VendorView";
import AddVendor from "./forms/AddVendor";
import RateVendor from "./forms/RateVendor";
import EditVendor from "./forms/EditVendor";
import Admin from "./admin/Admin";




import MyRest from "./MyRest";

import './App.css';


function App() {

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={ <Landing/>} />
          <Route exact path="/login" element={ <Login/>} />
          <Route exact path="/join" element={ <Join/>} />
          <Route path="/vendors/" element={< Vendors/>} />
          <Route path="/vendors/:vendorId" element={< VendorView/>} />
          <Route path="/vendors/add-vendor" element={<AddVendor />}/>
          <Route path="/vendors/rate-vendor/:vendorId" element={<RateVendor />}/>
          <Route path="/vendors/rate-vendor/:vendorId/:ratingId" element={<RateVendor />}/>
          <Route path="/vendors/edit-vendor/:vendorId/:type" element={<EditVendor />}/>
          <Route path="/vendors/edit-vendor/:vendorId/:type/:contactId" element={<EditVendor />}/>
          <Route path="/my-venue/" element={< MyRest/>} />
          <Route path="/admin" element={ <Admin/>} />
          <Route path="/*" element={ <Landing/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
import { useState, useContext } from 'react';
import { utilMethods } from './util/utilMethods';
import { firestoreMethods } from './firebase/firestoreMethods';
import { firestoreRest } from './firebase/RestProvider';
import { firebaseAnalytics } from './firebase/AnalyticsProvider';


import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StarFilled from '@mui/icons-material/Star';
import StarEmpty from '@mui/icons-material/StarOutline';

import Vote from './forms/Vote';

function VendorCardHeader({vendor}) {
  const { myRest, dbUser, setRefresh } = useContext(firestoreRest);
  const { trackEvent } = useContext(firebaseAnalytics);

  const [saved, setSaved] = useState(false);
  const [removed, setRemoved] = useState(false);
  const restExists = myRest && Object.keys(myRest).length !== 0;

  const handleAddVendorToRest = async() => {
    setSaved(true);
    utilMethods.customLog([["Vendor Card -> handleAddVendorToRest", ""]]);
    await firestoreMethods.addVendorToRest(vendor.id, vendor, myRest.id, dbUser);
    setRefresh(true);
    trackEvent("star");
  }

  const handleRemoveVendorToRest = async() => {
    setRemoved(true);
    utilMethods.customLog([["Vendor Card -> handleRemoveVendorToRest", ""]]);
    await firestoreMethods.removeVendorFromRest(vendor.id, vendor, myRest.id, dbUser);
    setRefresh(true);
    trackEvent("unstar");
  }

  const handleSaveClose = () => {
    setSaved(false);
  }

  const saveAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSaveClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const handleRemoveClose = () => {
    setRemoved(false);
  }

  const removeAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleRemoveClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  
  return (
    <>
      <div className='v-card-section'>
        
        <div className='v-card-header'>
          {(restExists && myRest.vendors && myRest.vendors.includes(vendor.id)) 
            ?               
              <Tooltip title='Click to remove from your Star list'>
                <StarFilled className='v-card-star-icon' onClick={handleRemoveVendorToRest}/>
              </Tooltip>
            :               
              <Tooltip title='Click to add to your Star list'>
                <StarEmpty className='v-card-star-icon' onClick={handleAddVendorToRest}/>
              </Tooltip>
          } 

          <div className='v-card-v-title'>{utilMethods.getVendorName(vendor)}</div>
          
          {(vendor["private"]) && <div className='v-card-private'>Private</div>}
          
          <Vote vendor={vendor}/>

        </div>

      </div>
     
      <Snackbar
        open={saved}
        autoHideDuration={8000}
        onClose={handleSaveClose}
        message="Vendor has been starred to your Star list"
        action={saveAction}
      />
      <Snackbar
        open={removed}
        autoHideDuration={8000}
        onClose={handleRemoveClose}
        message="Vendor has been removed from your Star list"
        action={removeAction}
      />
     
    </>
  );

}


export default VendorCardHeader;



import { useState, useContext } from 'react';
import { utilMethods } from '../util/utilMethods';
import { firestoreMethods } from '../firebase/firestoreMethods';
import { firebaseAnalytics } from '../firebase/AnalyticsProvider';

//import spoons from './assets/green_spoons.png';



function AddRest({dbUser, setRefresh}) {
  
  const { trackEvent } = useContext(firebaseAnalytics);
  const [restName, setRestName] = useState('');
  const [invalidName, setInvalidName] = useState();


  const handleRestName = (e) => {
    const {value} = e.target
    setRestName(value);
  }

  const handleSaveMyRest = async() => {
    if (restName === '') {
      setInvalidName("Please provide a name");
    } else {
      utilMethods.customLog([["Vendors -> handleSaveMyRest", ""]]);
      trackEvent("save_rest");
      await firestoreMethods.setRest(dbUser, restName); // the only way to refresh rest was to pass the set function
      setRefresh(true);
    }
  }

/*
  // race condition to make sure we don't go back before myRest is updated
  useEffect(() => {
    utilMethods.customLog([["AddRest -> UseEffect", `dbUser: ${dbUser}, myRest Vendors: ${myRest.vendors}`]]);
  }, [dbUser, myRest, navigate]);
*/

  //if (!myRest) return null;

  return (
    <>
      <div className='landing-outer'>
        <div className='landing-box'>
          <div className='add-rest-welcome'>
            Welcome to Spoon Village
          </div>
          
          <div className='landing-invite-code-outer'>
              <input 
                name="restName"
                type="text" 
                onChange={handleRestName} 
                placeholder="Name of your venue"
                value={restName}
                className="add-rest-input"
              />
              <div className='landing-submit-invite' onClick={handleSaveMyRest}>
               Save
              </div>
          </div>
          {invalidName && 
            <div className='error-error'>{invalidName}</div>
          }
          {/*<img src={spoons} className='spoons' alt="Spoons"/>/*/}
        </div>
      </div>
    </>
  );

}


export default AddRest;
